import React from "react";
// Customizable Area Start
import {
  Box, Typography, Grid, Drawer, TextField, Avatar, Checkbox,
  FormControlLabel, IconButton, DrawerProps, Radio, RadioGroup, BoxProps, Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, Breadcrumbs,
} from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { createTheme, styled, } from "@material-ui/core/styles";
import { My_Calender, Add_schedule, info, location, down, tick, milestone, cal, time, repeat, editIcon, deleteIcon, white_loc } from "./assets";
import CustomDropdown from '../../../components/src/CustomDropDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment-timezone';
import Sidebar from "../../../components/src/Sidebar.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CustomTimeZone from "../../../components/src/CustomTimeZone";
import CustomDropDownWithSearch from "../../../components/src/CustomDropDownWithSearch";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SideNavbar from "../../../components/src/SideNavbar.web";
import { buttonCancel } from "../../email-account-registration/src/assets";
import GenericTabs from "../../../components/src/GenericTabs.web";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
const localizer = momentLocalizer(moment)
import CustomPagination from "../../../components/src/CustomPagination.web";
export const baseURL = require("../../../framework/src/config");
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomTextEditor from "../../../components/src/CustomTextEditor";
import AddIcon from '@material-ui/icons/Add';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
const Time : { [key: string]: string } = {
  en: "Time",
  fr: "Temps",
  es: "Tiempo",
  de: "Zeit",
};  
const date : { [key: string]: string } = {
  en: "Date",
  fr: "Date",
  es: "Fecha",
  de: "Datum",
};  
const milestoneSelect: { [key: string]: string } = {
  en: "Please select milestone",
  fr: "Veuillez sélectionner un jalon",
  es: "Por favor seleccione hito",
  de: "Bitte Meilenstein auswählen",
};

const Selectmilestone: { [key: string]: string } = {
  "en": "Select Milestone",
  "fr": "Sélectionnez un jalon",
  "de": "Wählen Sie Meilenstein aus",
  "es": "Seleccionar hito"
};

const UpcomingAppointmentsText: { [key: string]: string } = {
  en: "Upcoming appointments are displayed here. Select a date to view details.",
  fr: "Les rendez-vous à venir sont affichés ici. Sélectionnez une date pour voir les détails.",
  es: "Las próximas citas se muestran aquí. Seleccione una fecha para ver los detalles.",
  de: "Hier werden die nächsten Termine angezeigt. Wählen Sie ein Datum aus, um Details anzuzeigen.",
};

const trackPatientText: { [key: string]: string } = {
  en: "Track patient attendance and monitor invite responses.",
  fr: "Suivez la fréquentation des patients et surveillez les réponses aux invitations.",
  es: "Realice un seguimiento de la asistencia de los pacientes y controle las respuestas a las invitaciones.",
  de: "Verfolgen Sie die Anwesenheit der Patienten und überwachen Sie die Antworten auf Einladungen.",
};

const PreviousTest: { [key: string]: string } = {
  "en": "Previous",
  "fr": "Précédent",
  "de": "Vorherige",
  "es": "Anterior"
}
const UpcomingTest: { [key: string]: string } = {
  "en": "Upcoming",
  "fr": "Prochain",
  "de": "Demnächst",
  "es": "Próximo"
}
const AddressLine1: { [key: string]: string } = {
  "en": "Address Line 1",
  "fr": "Adresse Ligne 1",
  "de": "Adresszeile 1",
  "es": "Dirección Línea 1"
}

const AddressLine2: { [key: string]: string } = {
  "en": "Address Line 2",
  "fr": "Ligne d'adresse 2",
  "de": "Adresszeile 2",
  "es": "Línea de dirección 2"
}

const AddressLine3: { [key: string]: string } = {
  "en": "Address Line 3",
  "fr": "Ligne d'adresse 3",
  "de": "Adresszeile 3",
  "es": "Línea de dirección 3"
}

const TitleText: { [key: string]: string } = {
  "en": "Title",
  "fr": "Titre",
  "de": "Titel",
  "es": "Título"
}

const StartDate: { [key: string]: string } = {
  "en": "Start Date",
  "fr": "Date de début",
  "de": "Startdatum",
  "es": "Fecha de inicio"
}

const StartTime: { [key: string]: string } = {
  "en": "Start Time",
  "fr": "Heure de début",
  "de": "Startzeit",
  "es": "Hora de inicio"
}

const DurationHour: { [key: string]: string } = {
  "en": "Duration Hour",
  "fr": "Durée Heure",
  "de": "Dauer Stunde",
  "es": "Duración Hora"
}
const AttendenceText: { [key: string]: string } = {
  "en": "Attendence",
  "fr": "Participation",
  "de": "Anwesenheit",
  "es": "Asistencia"
}
const back : { [key: string]: string } = {
  en: "Back",
  fr: "Dos",
  es: "Atrás",
  de: "Zurück",
};
const DailyText: { [key: string]: string } = {
  "en": "Daily",
  "fr": "Tous les jours",
  "de": "Täglich",
  "es": "A diario"
}

const WeeklyText: { [key: string]: string } = {
  "en": "Weekly",
  "fr": "Hebdomadaire",
  "de": "Wöchentlich",
  "es": "Semanalmente"
}
const MonthlyText: { [key: string]: string } = {
  "en": "Monthly",
  "fr": "Mensuel",
  "de": "Monatlich",
  "es": "Mensual"
}
const YearlyText: { [key: string]: string } = {
  "en": "Yearly",
  "fr": "Annuel",
  "de": "Jährlich",
  "es": "Anual"
}

const CustomText: { [key: string]: string } = {
  "en": "Custom",
  "fr": "Coutume",
  "de": "Brauch",
  "es": "Costumbre"
}
const yesText: { [key: string]: string } = {
  "en": "Yes",
  "fr": "Oui",
  "de": "Ja",
  "es": "Sí"
}
const noText: { [key: string]: string } = {
  "en": "No",
  "fr": "Non",
  "de": "NEIN",
  "es": "No"
}
const patientsText: { [key: string]: string } = {
  "en": "Patients",
  "fr": "Patients",
  "de": "Patienten",
  "es": "Pacientes"
}
// Customizable Area End

import AppointmentmanagementController, {
  Props,
  configJSON,
} from "./AppointmentmanagementController";

export default class Appointments extends AppointmentmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderParticipentsList = (item: any, flag: boolean) => {
    const ResponseRequired : { [key: string]: string } = {
      en: "Response Required",
      fr: "Réponse requise",
      es: "Respuesta requerida",
      de: "Antwort erforderlich",
    };
    return (
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Avatar style={webStyle.avatarstyle} src={item.profile_image ? baseURL.baseURL + item.profile_image : ''}>
          </Avatar>
        </Grid>
        <Grid item xs={8}>
          <Grid container >
            <Grid item xs={12}>
              <Typography style={webStyle.fullname}>{item.full_name}</Typography>
            </Grid>
            {flag ? (
              <Grid item xs={12}>
                <Typography style={webStyle.required}>{ResponseRequired[this.state.language ]}</Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography style={webStyle.required}>{item.invite_response}</Typography>
              </Grid>
            )}

          </Grid>
        </Grid>
        <Grid item xs={1} >
          {flag && (
            <IconButton data-test-id="cancel-tech1" onClick={() => this.deleteItem(item)}>
              <img src={buttonCancel} alt="buttonCancel" />
            </IconButton>
          )}

        </Grid>
      </Grid>
    )
  }

  rendergridView = (contentArray: any, text: string) => {
    const { itemsPerPage, displayedContent,currentPage } = this.state;
  let contentToDisplay = displayedContent.length > 0 ? displayedContent : contentArray.slice(0, itemsPerPage * currentPage);
  let startIndex = (currentPage - 1) * itemsPerPage;
  let endIndex = startIndex + itemsPerPage;
  contentToDisplay = contentToDisplay.slice(startIndex, endIndex);
  const Address : { [key: string]: string } = {
    en: "Address",
    fr: "Adresse",
    es: "DIRECCIÓN",
    de: "Adresse",
  };  

    return (
      <div>
        {contentToDisplay.map((content: any, index: any) => (

          <Box
            key={index}
            data-test-id={text}
            style={webStyle.detailsGrid}
            onClick={() => this.ShowAppointmentDetails(content)}>
            <Grid container spacing={2} className="formContainer"
              style={{ padding: "30px", marginTop: "20px", height: '255px' }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div >
                    <div style={webStyle.displayflex}>
                      <Typography style={webStyle.titleStyle}>
                        {content.attributes.title}
                      </Typography>
                      <div
                        data-test-id={text + "1"}
                        onClick={(e) => this.ShowMenu(e, content.id)}>
                        <MoreVertIcon />
                        {this.state.openMenu &&
                          (this.state.openMenuIndex === content.id) &&
                          (text === "Upcomming") &&
                          <div style={{ marginTop: '10px' }}>
                            {this.selectOptionMenu(content)}
                          </div>
                        }
                      </div>
                    </div>
                    <div
                      style={{
                        borderTop: '1px solid #cbcdd1',
                        width: '100%',
                        marginTop: '10px'
                      }} >
                    </div>
                  </div>


                </Grid>
                <Grid item xs={3}>
                  <div >
                    <Typography style={webStyle.titleStyle}>
                      {date[this.state.language ]} :
                    </Typography>
                    <Typography style={webStyle.descStyel}>
                      {this.callDateChangeFormat(content.attributes.start_date)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div > <Typography style={webStyle.titleStyle}>
                    {Time[this.state.language ]} (UTC) :
                  </Typography>
                    <Typography style={webStyle.descStyel}>
                      {content.attributes.start_time} to {content.attributes?.end_time}
                    </Typography></div>
                </Grid>
                <Grid item xs={12}>
                  <div > <Typography style={webStyle.titleStyle}>
                  {Address[this.state.language ]} :
                  </Typography>
                    <Typography style={webStyle.descStyel}>
                      {content.attributes.address}
                    </Typography></div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ))}
        {contentArray && (
          <Box style={webStyle.displayflex1}
          >
            <CustomPagination data={contentArray} itemsPerPage={itemsPerPage} edit={this.getUpdatedData} data-testid="custom-pagination" />

            <Box>
              {this.renderDropDown(this.state.openPages,
                this.state.pageCount, this.state.setPages,
                this.handleitemsPerPage, "page")}
            </Box>
          </Box>
        )}

      </div>

    )
  }
  renderMilestoneDrawerView() {
    return (
      <Box style={{ marginTop: '20px' }}>
        <div style={{ position: 'relative', width: '100%' }}>

          <div
            data-test-id='GetMilestone'
            style={webStyle.openmilestone} onClick={() => this.setIsOpen(!this.state.openMilestone)}>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }} >
              <img src={milestone} style={{ width: "20px", height: "20px", marginRight: "5px" }} />


              <span style={{
                color: this.state.selectedOption ? '#454545' : 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}
              >
                {this.state.selectedOption || Selectmilestone[this.state.language]}
              </span>
            </div>

            <img src={down} />
          </div>

          {this.renderMilestonArray(this.state.milestones, this.state.openMilestone, false)}

        </div>{this.state.milstoneError && (
          <Typography style={webStyle.passworderror}>{this.state.milstoneErrorText}</Typography>)}

      </Box>
    )
  }
  renderMilestoneView = () => {


    return (
      <Box style={{ marginTop: '20px' }}>
        <div style={{ position: 'relative', width: '100%' }}>

          <div
            style={{
              display: 'flex',
              padding: '10px',
              border: '1px solid #ccc',
              cursor: 'pointer',
              alignItems: 'center',
              borderRadius: '5px',
              justifyContent: 'space-between'
            }}
            data-test-id="withdrawermilestone"
            onClick={() => this.setIsOpen1(!this.state.openMilestoneWithDrawer)}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }} >
              <img src={milestone} style={{ width: "20px", height: "20px", marginRight: "5px" }} />


              <span style={this.state.selectedOption1 ? {
                color: '#454545',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              } : {
                color: 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}>
                {this.state.selectedOption1 || Selectmilestone[this.state.language]}
              </span>
            </div>

            <img src={down} />
          </div>
          {this.renderMilestonArray(this.state.milestoneNew, this.state.openMilestoneWithDrawer, true)}

        </div>
      </Box>
    )
  }
  renderMilestonArray = (ArrayNew: any, flag: boolean, drawerFlag: boolean) => {
    console.log(ArrayNew,'ArrayNewArrayNew');
    const NoMilestone : { [key: string]: string } = {
      en: "No milestone found",
      fr: "Aucun jalon trouvé",
      es: "No se encontró ningún hito",
      de: "Kein Meilenstein gefunden",
    };
    return (
      <>
        {flag && (
          <div style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            width: '100%',
            backgroundColor: '#fff',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            zIndex: 100
          }}>{ArrayNew && ArrayNew.length > 0 ? (
            <>
              {ArrayNew?.map((option: any, index: any) => (
                <div key={index} style={{
                  padding: '8px',
                  cursor: 'pointer'
                }}
                  data-test-id="clickmilestone2"
                  onClick={() => this.handleOptionClick(option, drawerFlag)}>
                  <span style={{
                    color: '#585858', fontFamily: 'Jost',
                    fontSize: '14px', fontStyle: 'normal', fontWeight: 400,
                    lineHeight: 'normal'
                  }}>{option.name}</span>

                </div>
              ))}
            </>
          ) :
          <div style={{
            padding: '8px',
            cursor: 'pointer'
          }}>
            <span style={{
              color: '#585858', fontFamily: 'Jost',
              lineHeight: 'normal',
              fontSize: '14px', fontStyle: 'normal', fontWeight: 400,
            }}>{NoMilestone[this.state.language ]}</span>

          </div>
          }
          </div>
        )}
      </>
    )
  }
  renderTitleDesView = () => {
    return (
      <>
        <Box style={{
          display: 'flex', paddingLeft: '12px',
          paddingRight: '8px',
          paddingTop: '4px',
          paddingBottom: '4px',
          border: '1px solid #ccc',
          cursor: 'pointer',
          alignItems: 'center',
          borderRadius: '5px',
          marginTop: '20px',
        }}>
          <img src={info} style={{ width: "20px", height: "20px", marginRight: "5px" }} />
          <TextField
            placeholder={TitleText[this.state.language]}
            InputProps={{ disableUnderline: true, }}
            style={{
              color: 'var(--Grey-Base, #939393)',
              fontFamily: 'Inter',
              fontSize: '6px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal', width: '100%',

            }}
            value={this.state.title}
            data-test-id="title"
            onChange={this.changeTitle}
            error={this.state.errorTitle !== ''}
          />
        </Box>

        <Typography style={webStyle.passworderror}>{this.state.errorTitle}</Typography>
        <CustomTextEditor language={this.state.language} handleEditorChange={this.handleEditorChange}
          onSelect={this.state.editorState} />

        <Typography style={webStyle.passworderror}>{this.state.errorDesc}</Typography>
      </>
    )
  }
  renderCustomDiv = () => {
    const RepeatEvery : { [key: string]: string } = {
      en: "Repeat Every",
      fr: "Répéter tous les",
      es: "Repetir cada",
      de: "Wiederholen Sie alle",
    };
    const OccursOn : { [key: string]: string } = {
      en: "Occurs On",
      fr: "Se produit le",
      es: "Ocurre en",
      de: "Tritt ein",
    };
    return (
      <>
        <Box style={{ marginTop: '20px' }}>
          <div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>

              <Grid container style={{ marginTop: '10px' }} >
                <Grid item xs={3} >
                  <Typography
                    style={{
                      color: '#5A5A5A',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '26px',
                      marginTop: '10px'
                    }}>
                    {RepeatEvery[this.state.language ]}
                  </Typography>

                </Grid>
                <Grid item xs={9}>
                  {/* Content for the second column */}

                  <Grid container spacing={2}>
                    {this.state.customValue !== "year" && (
                      <Grid item xs={6}>


                        {this.renderDropDown(this.state.customRepeatEveryFlag,
                          this.state.customRepeatEveryArray, this.state.customRepeatEveryValue,
                          this.handleCustomRepeatEvery, "1")}




                      </Grid>
                    )}
                    <Grid item xs={6}>
                      {this.renderDropDown(this.state.customFlag,
                        this.state.customArray, this.state.customValue,
                        this.handleCustomValue, CustomText[this.state.language])}

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </div>

          </div>
        </Box>
        {this.state.customValue === "Day" && (
          <>
            {this.renderEndDate()}
          </>
        )}
        {this.state.customValue === "Week" && (
          <>
            <Box style={{ marginTop: '20px' }}>
              <Typography style={{
                color: '#5A5A5A',
                fontFamily: 'Jost',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}>
                {OccursOn[this.state.language ]}
              </Typography>
              <Box>
                {this.renderWeek()}
              </Box>
            </Box>
            {this.renderEndDate()}
          </>
        )}
        {this.state.customValue === "Month" && (
          <>
            {this.renderMonthlySubDiv()}
          </>
        )}
        {this.state.customValue === "Year" && (
          <>
            {this.renderYearlyDiv()}
          </>
        )}
      </>
    )
  }
  renderWeek = () => {
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between',
        marginTop: '10px'
      }}>
        {this.state.weekdays.map((day: any, index: any) => (
          <div
            key={index}
            style={this.state.selectedDays === day.short ? {
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#A046FB', cursor: 'pointer'
            } : {
              width: '40px',
              height: '40px',
              borderRadius: '50%', backgroundColor: 'white', cursor: 'pointer'
            }}
            data-test-id={day.short}
            onClick={() => this.handleDayClick(day)}
          >

            <Typography style={this.state.selectedDays === day.short ? {
              color: '#FFF',
              fontFamily: 'Jost',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'
            } : {
              color: '#939393',
              fontFamily: 'Jost',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'
            }}
            >{day.short}</Typography>

          </div>
        ))}
      </div>
    )
  }
  renderEndDate = () => {
    const today = new Date();
    return (
      <>
        <Box style={{
          display: 'flex',
          paddingRight: '4px',
          paddingTop: '4px',
          paddingBottom: '4px',
          border: '1px solid #ccc',
          cursor: 'pointer',
          alignItems: 'center',
          borderRadius: '5px',
          marginTop: '20px',
          textAlign: 'center',
        }}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            placeholder="End Date"
            format="DD/MM/YYYY"
            value={this.state.endDate}
            data-test-id="enddate"
            minDate={today}
            onChange={this.handleEndDateChange}
            autoOk
            InputAdornmentProps={{ position: "start" }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              disableUnderline: true,
            }}
            keyboardIcon={<img src={cal} />} />

        </Box>
        {this.state.endDateError && (
          <Typography style={webStyle.passworderror}>{this.state.endDateErrorText}</Typography>)}
      </>
    )
  }
  renderMonthlySubDiv = () => {
    const OnDay : { [key: string]: string } = {
      en: "On Day",
      fr: "Le jour",
      es: "el dia",
      de: "Am Tag",
    };
    const Onthe : { [key: string]: string } = {
      en: "On the",
      fr: "Sur le",
      es: "en el",
      de: "Auf der",
    };
    return (
      <>
        <Box style={{ marginTop: '20px', }}>
          <RadioGroup
            name="status"
            data-test-id="radio1"
            value={this.state.selectedRadioMonth}
            onChange={this.handleOptionChangeRadioMonth}
            row
          >
            <Grid container >
              <Grid item xs={3}>
                <FormControlLabel
                  value="open"
                  control={<Radio color="primary" />}
                  label={
                    <Typography
                      style={{
                        color: '#5A5A5A',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '26px',
                      }}>
                      {OnDay[this.state.language ]}
                    </Typography>}
                />
              </Grid>
              <Grid item xs={9}>

                <TextField
                  InputProps={{ disableUnderline: true }}
                  style={{
                    display: 'flex', padding: '4px',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    alignItems: 'center',
                    borderRadius: '5px',
                    textAlign: 'center',
                  }}
                  data-test-id="onday"
                  value={this.state.on_day}
                  onChange={this.handleChangeOnDayValue}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '20px' }} >
              <Grid item xs={3} >
                <FormControlLabel
                  value="closed"
                  control={<Radio color="primary" />}
                  label={<Typography
                    style={{
                      color: '#5A5A5A',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '26px',
                    }}>
                    {Onthe[this.state.language ]}
                  </Typography>}
                />

              </Grid>
              <Grid item xs={9}>
                {/* Content for the second column */}

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {this.renderDropDown(this.state.openOnthe,
                      this.state.onthe, this.state.onthevalue,
                      this.handleOptionClickonthe, "Third")}

                  </Grid>
                  <Grid item xs={6}>
                    {this.renderDropDown(this.state.openDay,
                      this.state.dayOn, this.state.dayonvalue,
                      this.handleOptionClickDay, "Tuesday")}

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </RadioGroup>


        </Box>
        {this.renderEndDate()}
      </>

    )
  }
  renderMonthlyDiv = () => {
    return (
      <>
        {this.repeatEvery(this.state.MonthlyValue, this.handleChangeMonthlyValue, "Month")}
        {this.renderMonthlySubDiv()}



      </>
    )
  }
  renderYearlyDiv = () => {
    const On : { [key: string]: string } = {
      en: "On",
      fr: "Sur",
      es: "En",
      de: "An",
    };
    const Onthe : { [key: string]: string } = {
      en: "On the",
      fr: "Sur le",
      es: "en el",
      de: "Auf der",
    };
    return (
      <>
        <Box style={{ marginTop: '10px' }}>

          <RadioGroup
            name="status"
            data-test-id="radioYear"
            value={this.state.selectedRadioYear}
            onChange={this.handleOptionChangeRadioYear}


          >
            <div style={{ display: 'column' }}>
              <FormControlLabel
                value="open"
                control={<Radio color="primary" />}
                label={<Typography style={{
                  color: '#5A5A5A',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '26px',
                  width: '100%'
                }}>
                  {On[this.state.language ]} {this.state.yearlyDate}
                </Typography>}
              />


              <FormControlLabel
                value="closed"
                control={<Radio color="primary" />}
                label={<Typography style={{
                  color: '#5A5A5A',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '26px',
                  width: '100%'
                }}>
                  {Onthe[this.state.language ]} {this.state.yearlyDayFormat}
                </Typography>}
              />
            </div>

          </RadioGroup>

        </Box>
        {this.renderEndDate()}

      </>
    )
  }
  renderWeeklyDiv = () => {
    const OccursOn : { [key: string]: string } = {
      en: "Occurs On",
      fr: "Se produit le",
      es: "Ocurre en",
      de: "Tritt ein",
    };
    const OccursEvery : { [key: string]: string } = {
      en: "Occurs every",
      fr: "Se produit chaque",
      es: "Ocurre cada",
      de: "Kommt jeden vor",
    };
    const starting : { [key: string]: string } = {
      en: "starting",
      fr: "départ",
      es: "A partir de",
      de: "Beginnt",
    };
    return (
      <>
        {this.repeatEvery(this.state.WeeklyValue, this.handleChangeWeeklyValue, "Week")}

        <Box style={{ marginTop: '20px' }}>
          <Typography style={{
            color: '#5A5A5A',
            fontFamily: 'Jost',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          }}>
            {OccursOn[this.state.language ]}
          </Typography>
          <Box>
            {this.renderWeek()}

          </Box>
        </Box>
        {this.renderEndDate()}
        <Box style={{ marginTop: '20px' }}>
          <Typography style={{
            color: '#5A5A5A',
            fontFamily: 'Jost',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          }}>
            {OccursEvery[this.state.language ]} {this.state.selectedDayLong} {starting[this.state.language ]} {this.state.showDate}
          </Typography>
        </Box>
      </>
    )
  }
  repeatEvery = (value: any, func: any, text: any) => {
    return (
      <Box style={{
        display: 'flex', paddingLeft: '12px',
        paddingRight: '8px',
        paddingTop: '4px',
        paddingBottom: '4px',
        border: '1px solid #ccc',
        cursor: 'pointer',
        alignItems: 'center',
        borderRadius: '5px',
        marginTop: '20px',
        textAlign: 'center',
      }}>
        <img src={repeat} style={{ width: "20px", height: "20px", marginRight: "5px" }} />
        <TextField
          placeholder="Repeat Every"
          InputProps={{ disableUnderline: true }} // Disable underline
          style={{
            color: 'var(--Grey-Base, #939393)',
            fontFamily: 'Inter',
            fontSize: '6px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal', width: '80%', marginLeft: '10px'
          }}
          data-test-id={text}
          value={value}
          onChange={func}
        />
        <div style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
          <div style={{
            width: '1px',
            height: '28px',
            flexShrink: 0,
            backgroundColor: "#E6E6E6"
          }}>

          </div>
          <Typography style={{
            color: '#404040',
            fontFamily: 'Jost',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal', marginLeft: "5px",
          }}> {text}(s)</Typography>
        </div>

      </Box>
    )
  }
  renderDailyDiv = () => {
    return (
      <>
        {this.repeatEvery(this.state.dailyValue, this.handleChangeDailyValue, "Day")}

        {this.renderEndDate()}
      </>
    )
  }
  renderDropDown = (flag: any, newarray: any, value: any, func: any, text: any) => {

    return (
      <Box >
        <div style={{ position: 'relative', width: '100%' }}>

          <div style={{
            display: 'flex',
            padding: '15px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            alignItems: 'center',
            borderRadius: '5px',
            justifyContent: 'space-between'
          }}
            data-test-id={text + "1"}
            onClick={() => this.setStateFlag(flag, text)}>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }} >


              <Typography style={value ? {
                color: '#454545',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                marginLeft: '10px'
              } : {
                color: 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal', marginLeft: '10px'
              }}>
                {value || (text === "page" ? " " : text)}
                {text === "page" && " items per pages"}
              </Typography>
            </div>
            <ExpandLessIcon style={{color:'#707070'}}/>
          </div>
          {flag && (
            <div style={{
              position: 'absolute',
              bottom: '100%',
              left: '0',
              width: '100%',
              maxHeight: '200px', // Set max height to enable scrolling
              overflowY: 'auto', // Enable vertical scrolling
              backgroundColor: '#fff', /* Background color for options */
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              zIndex: 100
            }}>
              {newarray.map((option: any, index: any) => (
                <div key={index} style={{
                  padding: '8px',
                  cursor: 'pointer',

                }}
                  data-test-id={text}
                  onClick={() => func(option)}>
                  <span style={{
                    color: '#585858', fontFamily: 'Jost',
                    fontSize: '14px', fontStyle: 'normal', fontWeight: 400,
                    lineHeight: 'normal'
                  }}>{option} {text === "page" && "items per pages"}</span>

                </div>
              ))}
            </div>
          )}
        </div>
      </Box>
    )
  }
  selectOptionMenu = (content: any) => {
    const Edit : { [key: string]: string } = {
      en: "Edit",
      fr: "Modifier",
      es: "Editar",
      de: "Bearbeiten",
    };
    const Delete : { [key: string]: string } = {
      en: "Delete",
      fr: "Supprimer",
      es: "Borrar",
      de: "Löschen",
    };
    return (
      <div style={{ position: 'relative' }}>
        <EditOptionModalStyle>
          <React.Fragment>
            <Container className="container">
              <Grid item xs={12} >
                <Box style={{ display: "flex", cursor: 'pointer' }}
                  data-test-id="editbutton"
                  onClick={() => this.handleEditItemClick(content)}>
                  <img src={editIcon} />
                  <Typography
                    id="studyVal"
                    style={{
                      color: "#FFF",
                      fontFamily: "Jost",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600, marginLeft: '10px'
                    }}

                  >
                    {Edit[this.state.language ]}
                  </Typography></Box>
                <Box style={{ display: "flex", marginTop: '10px', cursor: 'pointer' }}
                  data-test-id="deletebutton"
                  onClick={() => this.showDailogDelete(content.id)}>
                  <img src={deleteIcon} />
                  <Typography
                    id="studyVal"
                    style={{
                      color: "#FFF",
                      fontFamily: "Jost",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600, marginLeft: '10px'
                    }}

                  >
                    {Delete[this.state.language ]}
                  </Typography>
                </Box>


              </Grid>
            </Container>
          </React.Fragment>

        </EditOptionModalStyle>
      </div>
    )
  }
  renderDrawerDetails = () => {
    const Hours : { [key: string]: string } = {
      en: "Hours",
      fr: "Heures",
      es: "Horas",
      de: "Std",
    };
    const Address : { [key: string]: string } = {
      en: "Address",
      fr: "Adresse",
      es: "DIRECCIÓN",
      de: "Adresse",
    };
    const duration : { [key: string]: string } = {
      en: "Duration",
      fr: "Durée",
      es: "Duración",
      de: "Dauer",
    };
    const Repeat : { [key: string]: string } = {
      en: "Repeat",
      fr: "Répéter",
      es: "Repetir",
      de: "Wiederholen",
    };
    const EveryDay : { [key: string]: string } = {
      en: "Every day ,until",
      fr: "Chaque jour, jusqu'à",
      es: "Todos los días, hasta",
      de: "Jeden Tag, bis",
    };
    const EveryWeek : { [key: string]: string } = {
      en: "Every week ,on",
      fr: "Chaque semaine, sur",
      es: "Cada semana, en",
      de: "ede Woche, am",
    };
    const DoesNotRepeat : { [key: string]: string } = {
      en: "Does Not Repeat",
      fr: "Ne se répète pas",
      es: "No se repite",
      de: "Wiederholt sich nicht",
    };
    const EveryMonth: { [key: string]: string } = {
      en: "Every month on the,",
      fr: "Chaque mois sur le",
      es: "Cada mes en el",
      de: "Jeden Monat am",
    };
    const Details: { [key: string]: string } = {
      en: "Details",
      fr: "Détails",
      es: "Detalles",
      de: "Einzelheiten",
    };
    const CheckMarkAttendance: { [key: string]: string } = {
      en: "Check & Mark Attendance",
      fr: "Vérifier et marquer la présence",
      es: "Verificar y marcar asistencia",
      de: "Überprüfen und markieren Sie die Anwesenheit",
    };
    const StartDateTime: { [key: string]: string } = {
      en: "Start Date and Time",
      fr: "Date et heure de début",
      es: "Fecha y hora de inicio",
      de: "Startdatum und -uhrzeit",
    };
    const EndDateTime: { [key: string]: string } = {
      en: "End Date & Time",
      fr: "Date et heure de fin",
      es: "Fecha y hora de finalización",
      de: "Enddatum und -uhrzeit",
    };
    const Every: { [key: string]: string } = {
      en: "Every",
      fr: "Chaque",
      es: "Cada",
      de: "Jeder",
    };
    return (
      <DrawerStyle anchor="right"
        open={this.state.showDetails}
        onClose={this.closeAppointmeentDetails}
      >
        <div style={{ width: 400, padding: '30px' }}>
          <Box >
            <IconButton style={{
              float: 'right',
              marginRight: "-10px",
              cursor: 'pointer',
              marginTop: '-20px'
            }} data-test-id="closeAppointment" onClick={() => this.closeAppointmeentDetails()}>
              <img src={buttonCancel} alt="buttonCancel" />
            </IconButton>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: "100%" }}>
            <Typography style={{
              color: '#292929',
              fontFamily: 'Jost',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '34px'
            }}>{this.state.SelectedAppointment.attributes?.title}</Typography>
          </Box>
          <Box>
            <Typography style={{
              color: '#8B8B8B',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              marginTop: '10px'
            }}>{this.state.SelectedAppointment.attributes?.time_zone} </Typography>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Typography style={{
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '22px',
              marginTop: '10px'
            }}>{StartDateTime[this.state.language ]} (UTC) </Typography>
          </Box>
          <Box style={{ marginTop: '20px', display: 'flex', }}>

            <img src={cal} />
            <Typography style={{ marginLeft: "20px" }}
            >{this.formattedDateStart(this.state.SelectedAppointment.attributes?.start_date)}</Typography>
            <Box>

              <Box style={{ display: 'flex', }}>
                <img src={time} style={{ marginLeft: "20px" }} />
                <Typography style={{ marginLeft: "20px" }}>{this.state.SelectedAppointment.attributes?.start_time}</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Typography style={webStyle.dateNew}>{EndDateTime[this.state.language ]} (UTC)</Typography>
          </Box>
          <Box style={{ marginTop: '20px', display: 'flex', }}>

            <img src={cal} />
            <Typography style={{ marginLeft: "20px" }}
            >{this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)}</Typography>
            <Box>
              <Box style={{ display: 'flex', }}>
                <img src={time} style={{ marginLeft: "20px" }} />
                <Typography style={{ marginLeft: "20px" }}>{this.state.SelectedAppointment.attributes?.end_time}</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Typography style={webStyle.duration}>{duration[this.state.language ]} </Typography>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Typography style={webStyle.hours}>{this.state.SelectedAppointment.attributes?.duration_hour} {Hours[this.state.language ]} {this.state.SelectedAppointment.attributes?.minute} Minute</Typography>
          </Box>

          <Box style={webStyle.box21}>

            <Typography style={{
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '22px',
              marginTop: '10px'

            }}>{Repeat[this.state.language ]}</Typography>
            <Box>
              {this.state.SelectedAppointment.attributes?.recurring_meeting === "daily" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>{EveryDay[this.state.language ]} {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.recurring_meeting === "weekly" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>{EveryWeek[this.state.language ]} {this.state.SelectedAppointment.attributes?.occurs_on} until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.recurring_meeting === "monthly" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>{EveryMonth[this.state.language ]} {this.state.SelectedAppointment.attributes?.on_day} of the month ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.recurring_meeting === "yearly" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>{Every[this.state.language ]} {this.state.SelectedAppointment.attributes?.month} ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}

              {this.state.SelectedAppointment.attributes?.recurring_meeting === null && (<Typography style={{
                color: '#8B8B8B',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
                marginTop: '10px',
                width: "150px",
                padding: '5px',
                backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
              }}>{DoesNotRepeat[this.state.language ]}</Typography>)}

{this.state.SelectedAppointment.attributes?.recurring_meeting === "custom" && (
  <>
   {this.state.SelectedAppointment.attributes?.custom_period === "Day" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>{EveryDay[this.state.language ]} {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
               {this.state.SelectedAppointment.attributes?.custom_period === "Week" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>{EveryDay[this.state.language ]} {this.state.SelectedAppointment.attributes?.occurs_on} until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.custom_period === "Month" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>Every month on the {this.state.SelectedAppointment.attributes?.on_day} of the month ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}
              {this.state.SelectedAppointment.attributes?.custom_period === "Year" && (
                <Typography style={{
                  color: '#8B8B8B',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                  marginTop: '10px',

                  padding: '5px',
                  backgroundColor: '#ccccca', borderRadius: '10px', marginBottom: '10px'
                }}>{Every[this.state.language ]} {this.state.SelectedAppointment.attributes?.month} ,until {this.formattedDateStart(this.state.SelectedAppointment.attributes?.end_date)},{this.state.SelectedAppointment.attributes?.repeat_every} </Typography>
              )}

  </>
)}
            </Box>
          </Box>
          <Box style={{ backgroundColor: '#ededeb', borderRadius: '10px', padding: "20px", marginTop: "20px" }}>

            <Typography style={webStyle.details}>{Details[this.state.language ]}</Typography>
            <Box style={{ width: '200px' }}>
              <TextField

                InputProps={{ disableUnderline: true }} // Disable underline
                style={{
                  color: 'var(--Grey-Base, #939393)',
                  fontFamily: 'Inter',
                  fontSize: '6px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
                multiline
                value={this.state.SelectedAppointment.attributes?.description}

                disabled
              />


            </Box>
            <Typography style={webStyle.addnew}>{Address[this.state.language ]}</Typography>
            <Box style={{ display: 'flex' }}>
              <img src={location} style={{
                width: "5%", height: "5%", marginRight: "5px", marginTop: '10px',
                marginBottom: '10px'
              }} />

              <Typography style={{
                color: '#8B8B8B',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
                marginTop: '10px',
                marginBottom: '10px'
              }}>{this.state.SelectedAppointment.attributes?.address}</Typography>
            </Box>
          </Box>

          <Box style={{ marginTop: '20px' }}>{
            this.state.SelectedAppointment.attributes?.patient_ids && this.state.SelectedAppointment.attributes?.patient_ids.length !== 0 && (
              <>
                <Box style={{ display: 'flex', }}>
                  <Box style={{
                    height: "30px",
                    flexShrink: 0, display: 'flex', alignItems: 'center',
                    backgroundColor: '#f2f3f5',
                    borderRadius: '15px',
                    padding: '3px'
                  }}>

                    <Typography style={{
                      color: '#454545',
                      fontFamily: 'Jost',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 200,
                      lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                    }}>{this.state.SelectedAppointment.attributes?.patient_ids.length} {patientsText[this.state.language]}</Typography>
                  </Box>
                  <Box style={{
                    height: "30px",
                    flexShrink: 0, display: 'flex', alignItems: 'center',
                    backgroundColor: '#f2f3f5',
                    borderRadius: '15px',
                    padding: '3px', marginLeft: '10px'
                  }}>

                    <Typography style={{
                      color: '#454545',
                      fontFamily: 'Jost',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 200,
                      lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                    }}>{this.calculateYesEResponce(this.state.SelectedAppointment?.attributes?.patient_ids)} {yesText[this.state.language]}</Typography>
                  </Box>
                  <Box style={{
                    height: "30px",
                    flexShrink: 0, display: 'flex', alignItems: 'center',
                    backgroundColor: '#f2f3f5',
                    borderRadius: '15px',
                    padding: '3px', marginLeft: '10px'
                  }}>

                    <Typography style={{
                      color: '#454545',
                      fontFamily: 'Jost',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 200,
                      lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                    }}>{this.calculateNoResponce(this.state.SelectedAppointment?.attributes?.patient_ids)} {noText[this.state.language]}</Typography>
                  </Box>
                </Box>

                {this.state.SelectedAppointment.attributes?.patient_ids.map((item: any, index: any) => (
                  <Box style={{ marginTop: '20px' }}>
                    {this.renderParticipentsList(item, false)}
                  </Box>

                ))}

              </>
            )
          }

          </Box>
          <Box style={{ marginTop: '20px' }}
            data-test-id='attendence'
            onClick={this.callAttendence}>
            <Typography
              style={{
                color: '#A046FB',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Jost',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                textDecorationLine: 'underline',
                cursor: "pointer"
              }}>{CheckMarkAttendance[this.state.language ]}</Typography>
          </Box>

        </div>

      </DrawerStyle>
    )
  }
  deleteDailog = () => {
    const DeleteSchedule: { [key: string]: string } = {
      en: "Delete Schedule",
      fr: "Supprimer le programme",
      es: "Eliminar horario",
      de: "Zeitplan löschen",
    };
    const SureWantDelete: { [key: string]: string } = {
      en: "Are you sure Do you want to Delete Selected Schedule",
      fr: "Êtes-vous sûr de vouloir supprimer le programme sélectionné",
      es: "¿Estás seguro? ¿Quieres eliminar el horario seleccionado?",
      de: "Sind Sie sicher, dass Sie den ausgewählten Zeitplan löschen möchten?",
    };
    const Delete: { [key: string]: string } = {
      en: "Delete",
      fr: "Supprimer",
      es: "Borrar",
      de: "Löschen",
    };
    const Cancel: { [key: string]: string } = {
      en: "Cancel",
      fr: "Annuler",
      es: "Cancelar",
      de: "Stornieren",
    };
    return (
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={this.state.deleteFlag}
        aria-labelledby="responsive-dialog-title" >
        <PasswordDialogStyle>
          <DialogActions>
            <IconButton data-test-id="cancel-password"
              onClick={() => this.setState({ deleteFlag: false })}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
            </IconButton>
          </DialogActions>
          <DialogTitle >
            <Typography className="dialog-title">{DeleteSchedule[this.state.language ]}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography className="dialog-para">
              {SureWantDelete[this.state.language ]}
            </Typography></DialogContent>
          <div style={{ display: 'flex', margin: '20px', justifyContent: "space-between", }}>
            <Button variant="outlined" className="change-password-btn"
              onClick={() => this.handleDeleteItemClick()}
              type='reset'
              data-test-id="deleteschedule"
            >{Delete[this.state.language ]}</Button>
            <Button variant="outlined" className="change-password-btn"
              onClick={() => this.setState({ deleteFlag: false })}
              type='reset'
              data-test-id="cancelschedule"
            >{Cancel[this.state.language ]}</Button></div></PasswordDialogStyle>
      </Dialog>
    )
  }
  renderReccuranceSectino = () => {
    return(
      <>
      <Box style={{ marginTop: '20px' }}>
              <CustomDropdown language= {this.state.language} options={this.state.Reccurance} onSelect={this.hadleSelectReccurance} icons={false} selectedOptionSet={this.state.value} />
              {(this.state.value.toLowerCase()) === DailyText[this.state.language || "en"].toLowerCase() && (
                <>
                  {this.renderDailyDiv()}</>
              )}
              {(this.state.value.toLowerCase()) === WeeklyText[this.state.language || "en"].toLowerCase() && (
                <>{this.renderWeeklyDiv()}</>
              )}
              {(this.state.value.toLowerCase()) === MonthlyText[this.state.language || "en"].toLowerCase() && (
                <>{this.renderMonthlyDiv()}</>
              )}
              {(this.state.value.toLowerCase()) === YearlyText[this.state.language || "en"].toLowerCase() &&
                (
                  <>{this.renderYearlyDiv()}</>
                )}
              {(this.state.value.toLowerCase()) === CustomText[this.state.language || "en"].toLowerCase() && (
                <>{this.renderCustomDiv()}</>
              )}
            </Box>
      </>
    )
  }
  renderDrawerMenu = () => {
    const timezones = moment.tz.names();
    const today = new Date();
    const Address : { [key: string]: string } = {
      en: "Address",
      fr: "Adresse",
      es: "DIRECCIÓN",
      de: "Adresse",
    };
    const UTCTime: { [key: string]: string } = {
      en: "UTC time is:",
      fr: "L'heure UTC est :",
      es: "La hora UTC es:",
      de: "Die UTC-Zeit ist:",
    };
    const RecurringMeeting: { [key: string]: string } = {
      en: "Recurring Meeting",
      fr: "Réunion récurrente",
      es: "Reunión recurrente",
      de: "Wiederkehrendes Treffen",
    };
    const Cancel: { [key: string]: string } = {
      en: "Cancel",
      fr: "Annuler",
      es: "Cancelar",
      de: "Stornieren",
    };
    const Save: { [key: string]: string } = {
      en: "Save",
      fr: "Sauvegarder",
      es: "Ahorrar",
      de: "Speichern",
    };
    return (
      <DrawerStyle anchor="right"
        open={this.state.open}
        onClose={this.handleDrawerCloseAdd}
      >
        <div style={styles.sideBarSize}>
          <Box >
            <IconButton style={{
              float: 'right',
              marginRight: "-10px",
              cursor: 'pointer',
              marginTop: '-20px'
            }} data-test-id="cancel-tech" onClick={() => this.handleDrawerCloseAdd()}>
              <img src={buttonCancel} alt="buttonCancel" />
            </IconButton>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: "100%" }}>
            <Typography style={{
              color: '#292929',
              fontFamily: 'Jost',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '34px'
            }}>{this.state.add_Schedule_Title}</Typography>
          </Box>
          {this.renderMilestoneDrawerView()}
          {this.renderTitleDesView()}

          <Box style={{ marginTop: '20px' }}>
            <CustomDropDownWithSearch
            language={this.state.language}
              edit={this.getData}
              options={this.state.patientList}
              onSelect={this.handleSelect}
              SelectedPatients={this.state.addPatientList}
              disableflag={this.state.disableFlag} />
            {this.state.addPatientList.length === 0 && (
              <>
                {this.state.patientError && (
                  <Typography style={webStyle.passworderror}>{this.state.patientErrorText}</Typography>)}
              </>)}
          </Box>

          {this.state.addPatientList && (

            <Box style={{ marginTop: '20px' }}>
              {this.state.addPatientList.map((item: any, index: any) => (
                this.renderParticipentsList(item, true)
              ))}
            </Box>

          )}




          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={2} >
              <Grid item xs={6} >
                <Box style={{
                  borderRadius: '4px',
                  border: '1px solid #E6E6E6',
                  padding: '8px'
                }}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    placeholder={StartDate[this.state.language]}
                    format="DD/MM/YYYY"
                    value={this.state.selectedDate}
                    data-test-id="startdate"
                    onChange={this.handleDateChange}
                    autoOk
                    minDate={today}
                    InputAdornmentProps={{ position: "start" }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    keyboardIcon={<img src={cal} />} />

                </Box>  {this.state.startDateError && (
                  <Typography style={webStyle.passworderror}>{this.state.startDateErrorText}</Typography>)}
              </Grid>
              <Grid item xs={6}>
                <Box style={{
                  borderRadius: '4px',
                  border: '1px solid #E6E6E6',
                  padding: '8px'
                }} >
                  <KeyboardTimePicker
                    disableToolbar
                    variant="inline"
                    placeholder={StartTime[this.state.language]}
                    autoOk
                    ampm={false}
                    value={this.state.selectedTime}
                    data-test-id="handletime"
                    onChange={this.handleTimeChange}
                    minutesStep={5}
                    keyboardIcon={<img src={time} alt="Clock" />}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    InputAdornmentProps={{ position: "start" }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                {this.state.startTimeError && (
                  <Typography style={webStyle.passworderror}>{this.state.startTimeErrorText}</Typography>)}
              </Grid>
              {this.state.selectedApiTime && (
                <>
                  <Grid item xs={6} justifyContent="center" alignItems="center">
                    <Typography style={{textAlign: "right"}}>{UTCTime[this.state.language]}</Typography>
                  </Grid>
                  <Grid item xs={6} justifyContent="center" alignItems="center">
                    <Typography >{this.callFormattedDateShow(this.state.selectedApiTime)}</Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                {this.renderDropDown(this.state.openDuration,
                  this.state.durations, this.state.setDuration,
                  this.handleOptionClickDuration, DurationHour[this.state.language])}
                {this.state.durationError && (
                  <Typography style={webStyle.passworderror}>{this.state.durationErrorText}</Typography>)}
              </Grid>
              <Grid item xs={6}>
                {this.renderDropDown(this.state.openMinute,
                  this.state.minutes, this.state.setMinute,
                  this.handleOptionClickMinute, "Minute")}
                {this.state.minuteError && (
                  <Typography style={webStyle.passworderror}>{this.state.minuteErrorText}</Typography>)}

              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginTop: '20px', display: 'flex', padding: '10px' }}>
            <FormControlLabel
              label=''
              control={
                <Checkbox style={{
                  borderRadius: '6.667px',
                  border: '1.667px solid #E6E6E6',
                  background: 'var(--White, #FFF)',
                }}
                  name='check'
                  value={this.state.checked}
                  icon={<span style={{
                    width: '4px',
                    height: '4px',
                    flexShrink: 0, padding: '1px'
                  }} />}
                  checkedIcon={<img src={tick} />} />}
              data-test-id="checkTick"
              disabled={this.state.selectedDate === null}
              onChange={(e) => this.checkedBox(e)}
            />
            <Typography style={{
              color: '#5A5A5A',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '26px',
            }}>
              {RecurringMeeting[this.state.language ]}
            </Typography>
          </Box>
          {this.state.checked && (
              this.renderReccuranceSectino()
          )}

          <Box style={{ marginTop: '20px' }}>
            <Typography style={{
              color: '#292929',
              fontFamily: 'Jost',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '26px',
            }}>{Address[this.state.language ]}</Typography>
          </Box>
          <Box style={{
            display: 'flex', paddingLeft: '12px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            alignItems: 'center',
            borderRadius: '5px',
            marginTop: '20px'
          }}>
            <img src={location} style={{ width: "4%", height: "4%", marginRight: "5px" }} />
            <TextField
              placeholder={AddressLine1[this.state.language]}
              InputProps={{ disableUnderline: true }} // Disable underline
              style={webStyle.addresstext}
              value={this.state.address1}
              data-test-id="add1"
              onChange={this.changeAddress1}
            />

          </Box>
          <Typography style={webStyle.passworderror}>{this.state.erroradd1}</Typography>
          <Box style={{
            display: 'flex', paddingLeft: '12px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            alignItems: 'center',
            borderRadius: '5px',
            marginTop: '10px'
          }}>
            <img src={location} style={{ width: "4%", height: "4%", marginRight: "5px" }} />
            <TextField
              placeholder={AddressLine2[this.state.language]}
              InputProps={{ disableUnderline: true }} // Disable underline
              style={{
                color: 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '6px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal', marginLeft: '10px'
              }}
              value={this.state.address2}
              data-test-id="add2"
              onChange={this.changeAddress2}
            />

          </Box>
          <Typography style={webStyle.passworderror}>{this.state.erroradd2}</Typography>
          <Box style={{
            display: 'flex', paddingLeft: '12px',
            paddingRight: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            alignItems: 'center',
            borderRadius: '5px',
            marginTop: '10px'
          }}>
            <img src={location} style={{ width: "4%", height: "4%", marginRight: "5px" }} />
            <TextField
              placeholder={AddressLine3[this.state.language]}
              InputProps={{ disableUnderline: true }} // Disable underline
              style={{
                color: 'var(--Grey-Base, #939393)',
                fontFamily: 'Inter',
                fontSize: '6px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal', marginLeft: '10px'
              }}
              value={this.state.address3}
              data-test-id="add3"
              onChange={this.changeAddress3}
            />

          </Box>
          {this.state.addressError && (
            <Typography style={webStyle.passworderror}>{this.state.addressErrorText}</Typography>)}
          <Typography style={webStyle.passworderror}>{this.state.erroradd3}</Typography>
          <Box style={{ marginTop: "20px", marginBottom: '80px', display: 'flex', }}>
            <Box style={{
              borderRadius: '8px',
              border: '2px solid #A046FB', padding: '5px', cursor: 'pointer'
            }}
              data-test-id="saveclick"
              onClick={this.SelectMethod}>
              <Typography style={{
                color: '#2B2B2B',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Jost',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '28px',
              }}>
                {Save[this.state.language ]}
              </Typography>
            </Box>
            <Box style={{
              borderRadius: '8px',
              border: '2px solid #8F8F8F', padding: '5px', marginLeft: '20px', cursor: 'pointer'
            }}
              data-test-id="cancelclick"
              onClick={() => { this.setState({ open: false }) }}>
              <Typography style={{
                color: '#2B2B2B',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Jost',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '28px',
              }}>
                {Cancel[this.state.language ]}
              </Typography>
            </Box>
          </Box>
        </div>

      </DrawerStyle>
    )
  }
  renderPreviousSchedule = () => {
    return (<Box style={{ marginTop: '20px', overflowX: 'hidden'}}>
      {this.state.previous && this.state.previous.length !== 0 ? (
        <> {this.rendergridView(this.state.previous, PreviousTest[this.state.language])}
        </>
      ) : 
       <Typography style={{color: '#0009'}}>{milestoneSelect[this.state.language]}</Typography>
      }


    </Box>)
  }
  renderUpcommingSchedule = () => {
    return (<Box style={{ marginTop: '20px', overflowX: "hidden" }}>
      {this.state.upcomming && this.state.upcomming.length !== 0 ? (
        <>{this.rendergridView(this.state.upcomming, "Upcomming")}
        </>
      ):
       <Typography style={{color: '#0009'}}>{milestoneSelect[this.state.language]}</Typography>
      }

    </Box>)
  }
  eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
    const style = {
      backgroundColor: '#352351',
    };

    return {
      style: style
    };
  };
  EventComponent = ({ event }: any) => {
    const { title,  address } = event;

    return (
      <div style={{
        backgroundColor: '#352351',
        height:'20px',
        color: 'white',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      }}>
        <Typography style={{
          color: '#fff',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
        }}>{title}</Typography>
        <Box style={{ display: 'flex' ,alignItems:'center'}}>
          <LocationOnOutlinedIcon  style={{marginRight: "5px"}}/>

          <Typography style={{
            color: '#fff',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
          }}>{address}</Typography>
        </Box>

      </div>
    );
  };
  sideBar =()=>{
    return(
      <SideNavbar
          navigation={this.props.navigation}
          id={this.props.id}
          showStudy={true}
          showButtonChats
          showButtonNotification
          language={this.state.language}
          handleLanguageSwitch={this.handleLanguageSwitch}
        />
    )
  }
  Attendence = () =>{
    return(
      <>
      {this.state.attendencePatientList && (
        <Box style={webStyle.displayflex1}
        >
          <CustomPagination data={this.state.attendencePatientList} itemsPerPage={this.state.itemsPerPage}
            edit={this.getUpdatedData} data-testid="custom-pagination" />

          <Box>
            {this.renderDropDown(this.state.openPages,
              this.state.pageCount, this.state.setPages,
              this.handleitemsPerPage, "page")}
          </Box>
        </Box>
      )}
      </>
    )
  }
  SelectTab=()=>{
    return(
      <>
      {this.state.selectedTab === PreviousTest[this.state.language] && (
        <>
          {
            this.renderPreviousSchedule()
          }
        </>
       )}
      </>
    )
  }
  Phonenumber = (row:any)=>{
    return(
      <Typography style={webStyle.descStyel}>
        {row.country_code ? `+${row.country_code} ` : ''}{row.phone_number}
      </Typography>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const calendar : { [key: string]: string } = {
      en: "Calendar",
      fr: "Calendrier",
      es: "Calendario",
      de: "Kalender",
    };
    const addSchedule : { [key: string]: string } = {
      en: "Add New Schedule",
      fr: "Ajouter un nouveau programme",
      es: "Agregar nuevo horario",
      de: "Neuen Zeitplan hinzufügen",
    };
    const visitSchedule : { [key: string]: string } = {
      en: "Visit Schedule",
      fr: "Calendrier des visites",
      es: "Horario de visitas",
      de: "Besuchsplan",
    };
    const startDate : { [key: string]: string } = {
      en: "Start Date & Time",
      fr: "Date et heure de début",
      es: "Fecha y hora de inicio",
      de: "Startdatum und -uhrzeit",
    };
    const endDate : { [key: string]: string } = {
      en: "End Date & Time",
      fr: "Date et heure de fin",
      es: "Fecha y hora de finalización",
      de: "Enddatum und -uhrzeit",
    };
    const duration : { [key: string]: string } = {
      en: "Duration",
      fr: "Durée",
      es: "Duración",
      de: "Dauer",
    };
    const Hours : { [key: string]: string } = {
      en: "Hours",
      fr: "Heures",
      es: "Horas",
      de: "Std",
    };
    const Address : { [key: string]: string } = {
      en: "Address",
      fr: "Adresse",
      es: "DIRECCIÓN",
      de: "Adresse",
    };
    const SendNotification : { [key: string]: string } = {
      en: "Send Notification",
      fr: "Envoyer une notification",
      es: "Enviar notificación",
      de: "Benachrichtigung senden",
    };
    const sentSuccessfully : { [key: string]: string } = {
      en: "Notification sent successfully",
      fr: "Notification envoyée avec succès",
      es: "Notificación enviada exitosamente",
      de: "Benachrichtigung erfolgreich gesendet",
    };
    const Attendance : { [key: string]: string } = {
      en: "Attendance",
      fr: "Présence",
      es: "Asistencia",
      de: "Teilnahme",
    };
    const PatientResponse : { [key: string]: string } = {
      en: "Patient Response",
      fr: "Réponse du patient",
      es: "Respuesta del paciente",
      de: "Patientenreaktion",
    };
    const PatientName : { [key: string]: string } = {
      en: "Patient Name",
      fr: "nom du patient",
      es: "nombre del paciente",
      de: "PatientName",
    };
    const InviteResponse : { [key: string]: string } = {
      en: "Invite Response",
      fr: "Inviter une réponse",
      es: "Invitar a responder",
      de: "Antwort einladen",
    };
    const PhoneNumber : { [key: string]: string } = {
      en: "Phone Number",
      fr: "numéro de téléphone",
      es: "número de teléfono",
      de: "Telefonnummer",
    };
    const CoordinatorResponse : { [key: string]: string } = {
      en: "Coordinator Response",
      fr: "Réponse du coordonnateur",
      es: "Respuesta del coordinador",
      de: "Antwort des Koordinators",
    };
    const NoResponse : { [key: string]: string } = {
      en: "No Response",
      fr: "Aucune réponse",
      es: "Sin respuesta",
      de: "Keine Antwort",
    };
    const MyCalender : { [key: string]: string } = {
      en: "My Calender",
      fr: "Mon calendrier",
      es: "mi calendario",
      de: "Mein Kalender",
    };
    const titleHeading : { [key: string]: string } = {
      en: "Welcome to your Visit Schedules tab! Here, you can create new visit schedules and view both upcoming and past visit schedules.",
      fr: "Bienvenue dans votre onglet Horaires de visite ! Ici, vous pouvez créer de nouveaux calendriers de visites et afficher les calendriers de visites à venir et passés.",
      es: "¡Bienvenido a la pestaña Horarios de visitas! Aquí puede crear nuevos horarios de visitas y ver los horarios de visitas pasadas y futuras.",
      de: "Willkommen auf der Registerkarte „Besuchspläne“! Hier können Sie neue Besuchspläne erstellen und sowohl bevorstehende als auch vergangene Besuchspläne anzeigen",
    };
    return (
      <>
        {this.sideBar()}

        <Box style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
          <Sidebar navigation={this.props.navigation} id={this.props.id} selected="visit_schedule" language={this.state.language}/>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            {this.state.myCalenderFlag ? (
              <Grid container >
                <Grid item xs={12} sm={9} style={{height:"100vh", overflow:"scroll", scrollbarWidth: "none"}}>
              <Box style={webStyle.mainBoxFirst}>
                <Grid container style={webStyle.boxNewSecound}>
                <Grid item xs={12} sm={6}>
                  <Typography style={webStyle.box2}>{calendar[this.state.language ]}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} style={webStyle.box3}>
                    <Button
                      style={webStyle.imgButton2}
                      data-test-id='AddSchedule'
                      onClick={this.toggleDrawer}
                      startIcon={<AddIcon/>}
                    >
                      {addSchedule[this.state.language ]}
                    </Button>
                  </Grid>
                </Grid>
                <Box style={{
                  marginBottom: "10px"
                }}>
                  <Typography style={webStyle.para}>{UpcomingAppointmentsText[this.state.language ]}</Typography>
                </Box>
                <Box style={{ marginTop: '20px', display: 'flex',alignItems:'center',justifyContent:"center",gap:'20px' }}>
                  <Button
                    onClick={this.openCalender}
                    variant="outlined"
                    style={styles.backButton}
                    startIcon={<KeyboardBackspaceIcon style={{ color: 'black' }} />}
                  >
                      {back[this.state.language ]}
                  </Button>
                  <Grid style={styles.sideBackButton}>
                    <Typography style={styles.btnVisit}>
                    {visitSchedule[this.state.language ]}
                    </Typography>
                    <ArrowRightIcon style={styles.textIcon} />
                    <Typography style={styles.btnCalender}>
                    {calendar[this.state.language ]}
                    </Typography>
                  </Grid>

                </Box>
                <Box style={{ marginTop: "20px", width: '100%' }}>

                  <Calendar
                    localizer={localizer}
                    events={this.state.scheduleOnCalender}
                    defaultView="week"
                    defaultDate={new Date()}
                    style={{ marginBottom: '50px',height:'100vh' }}
                    onNavigate={this.handleNavigate}
                    onSelectEvent={this.handleEventClick}
                    eventPropGetter={this.eventStyleGetter}
                    components={{
                      event: this.EventComponent, // Custom event component
                    }}
                    views={['month', 'week', 'day']}
                  />                  
                  {this.state.selectedEvent && (
                    <div style={{
                      width: 350, padding: '30px', position: 'absolute',
                      top: '15%',
                      left: '35%',
                      backgroundColor: '#fff',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.8)',
                      borderRadius: '5px',
                      zIndex: 100
                    }}>
                      <IconButton style={{
                        float: 'right',
                        marginRight: "-10px",
                        cursor: 'pointer',
                        marginTop: '-20px'
                      }}
                        data-test-id="viewclose"
                        onClick={() => this.setState({ selectedEvent: null })}>
                        <img src={buttonCancel} alt="buttonCancel" />
                      </IconButton>
                      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: "100%" }}>
                        <Typography style={{
                          color: '#292929',
                          fontFamily: 'Jost',
                          fontSize: '24px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '34px'
                        }}>{this.state.selectedEvent.title}</Typography>

                      </Box>
                      <Box>
                        <Typography style={{
                          color: '#8B8B8B',
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '22px',
                          marginTop: '10px'
                        }}>{this.state.selectedEvent.time_zone} </Typography>
                      </Box>
                      <Box style={{ marginTop: '20px' }}>
                        <Typography style={{
                          color: '#000',
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: '22px',
                          marginTop: '10px'
                        }}>{startDate[this.state.language ]} </Typography>
                      </Box>
                      <Box style={{ marginTop: '20px', display: 'flex', }}>

                        <img src={cal} />
                        <Typography style={{ marginLeft: "20px" }}
                        >{this.formattedDateStart(this.state.selectedEvent.ssdate)}</Typography>
                        <Box>

                          <Box style={{ display: 'flex', }}>
                            <img src={time} style={{ marginLeft: "20px" }} />
                            <Typography style={{ marginLeft: "20px" }}>{this.state.selectedEvent.sstime}</Typography>

                          </Box>


                        </Box>

                      </Box>
                      <Box style={{ marginTop: '20px' }}>
                        <Typography style={webStyle.dateNew}>{endDate[this.state.language ]} </Typography>
                      </Box>
                      <Box style={{ marginTop: '20px', display: 'flex', }}>

                        <img src={cal} />
                        <Typography style={{ marginLeft: "20px" }}
                        >{this.formattedDateStart(this.state.selectedEvent.eedate)}</Typography>
                        <Box>

                          <Box style={{ display: 'flex', }}>
                            <img src={time} style={{ marginLeft: "20px" }} />
                            <Typography style={{ marginLeft: "20px" }}>{this.state.selectedEvent.eetime}</Typography>

                          </Box>


                        </Box>

                      </Box>
                      <Box style={{ marginTop: '20px' }}>
                        <Typography style={webStyle.duration}>{duration[this.state.language ]} </Typography>
                      </Box>
                      <Box style={{ marginTop: '20px' }}>
                        <Typography style={webStyle.hours}>{this.state.selectedEvent.duration_hour} {Hours[this.state.language ]} {this.state.selectedEvent.minute} Minute</Typography>
                      </Box>


                      <Box style={{ backgroundColor: '#ededeb', borderRadius: '10px', padding: "20px", marginTop: "20px" }}>


                        <Typography style={webStyle.addnew}>{Address[this.state.language ]}</Typography>
                        <Box style={{ display: 'flex' }}>
                          <img src={location} style={{
                            width: "5%", height: "5%", marginRight: "5px", marginTop: '10px',
                            marginBottom: '10px'
                          }} />

                          <Typography style={{
                            color: '#8B8B8B',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '22px',
                            marginTop: '10px',
                            marginBottom: '10px'
                          }}>{this.state.selectedEvent.address}</Typography>
                        </Box>
                      </Box>
                      <Box style={{ marginTop: '20px' }}>{
                        this.state.selectedEvent.patient_ids && this.state.selectedEvent.patient_ids.length !== 0 && (
                          <>
                            <Box style={{ display: 'flex', }}>
                              <Box style={{
                                height: "30px",
                                flexShrink: 0, display: 'flex', alignItems: 'center',
                                backgroundColor: '#f2f3f5',
                                borderRadius: '15px',
                                padding: '3px'
                              }}>

                                <Typography style={{
                                  color: '#454545',
                                  fontFamily: 'Jost',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: 200,
                                  lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                                }}>{this.state.selectedEvent.patient_ids.length} patients</Typography>
                              </Box>
                              <Box style={{
                                height: "30px",
                                flexShrink: 0, display: 'flex', alignItems: 'center',
                                backgroundColor: '#f2f3f5',
                                borderRadius: '15px',
                                padding: '3px', marginLeft: '10px'
                              }}>

                                <Typography style={{
                                  color: '#454545',
                                  fontFamily: 'Jost',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: 200,
                                  lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                                }}>{this.calculateYesEResponce(this.state.selectedEvent?.patient_ids)} yes</Typography>
                              </Box>
                              <Box style={{
                                height: "30px",
                                flexShrink: 0, display: 'flex', alignItems: 'center',
                                backgroundColor: '#f2f3f5',
                                borderRadius: '15px',
                                padding: '3px', marginLeft: '10px'
                              }}>

                                <Typography style={{
                                  color: '#454545',
                                  fontFamily: 'Jost',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: 200,
                                  lineHeight: '26px', marginLeft: '5px', marginRight: '5px'
                                }}>{this.calculateNoResponce(this.state.selectedEvent?.patient_ids)} no</Typography>
                              </Box>
                            </Box>

                            {this.state.selectedEvent.patient_ids.map((item: any, index: any) => (
                              <Box style={{ marginTop: '20px' }}>
                                {this.renderParticipentsList(item, false)}
                              </Box>

                            ))}

                          </>
                        )
                      }

                      </Box>
                      <Box style={{ marginTop: '20px' }}
                        data-test-id='attendence'
                      >
                        <Button
                        onClick={()=> this.notificationApi()}
                        data-test-id='notificationApiattendence'
                          style={{
                            color: '#A046FB',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: 'Jost',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textDecorationLine: 'underline',
                            textTransform:'none'
                          }}>{SendNotification[this.state.language ]}</Button>
                            <Dialog open={this.state.isDialogOpen}>
                              <p style={{ fontFamily: "Jost", fontSize: "23px", padding: '0 22px 10px 30px', fontStyle: "normal", fontWeight: 600, }}>{sentSuccessfully[this.state.language ]}</p>
                              <Button data-test-id='dialogCloseattendence' onClick={this.handleDialogClose} style={{ margin: '20px', textTransform: 'none', fontFamily: "Jost", fontSize: "17px", fontStyle: "normal", border: '2px solid rgb(160, 70, 251)', borderRadius: '8px' }}>
                                Ok
                              </Button>
                            </Dialog>
                            
                      </Box>

                    </div>
                  )}


                </Box>
                {this.renderDrawerMenu()}
              </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
              </Grid>
              </Grid>
            ) : (
              <>
                {
                  this.state.openAttendence ? (
                    <>
                      <Box
                        style={webStyle.mainBox}>
                        <Box style={webStyle.boxNew}>
                          <Typography style={webStyle.box2}>{Attendance[this.state.language ]}</Typography>

                        </Box>
                        <Box>
                          <Typography style={webStyle.para}>{trackPatientText[this.state.language ]}</Typography>
                        </Box>




                        <div style={{ display: 'flex', marginTop: '20px' }}>
                          <Button
                            data-test-id="closeAttendence"
                            onClick={this.closeAttendence}
                            variant="outlined"
                            style={{
                              borderRadius: 5,
                              border: "2px solid #A046FB",
                              textTransform: 'none'
                            }}
                            startIcon={<KeyboardBackspaceIcon style={{ color: 'black' }} />}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                color: "#292929",
                                fontSize: 18,
                                fontWeight: 600,
                                fontFamily: 'Inter'
                              }}
                            >
                              {back[this.state.language]}
                            </Typography>
                          </Button>
                          <Breadcrumbs
                            style={{
                              display: 'flex',
                              flex: 1,
                              alignItems: 'center',
                              paddingLeft: 16,
                              marginLeft: 16,
                              background: 'rgba(192, 192, 192, 0.2)',
                              borderRadius: 10,
                            }}
                            separator={<ArrowRightIcon style={{ color: '#A046FB' }} />}
                            aria-label="breadcrumb">
                            <Button>
                              <Typography style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: 'rgba(192, 192, 192, 0.9)',
                                textTransform: 'none'
                              }}>
                                Visit Schedule
                              </Typography>
                            </Button>
                            <Button>
                              <Typography style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: 'rgba(192, 192, 192, 0.9)',
                                textTransform: 'none'
                              }}>
                                {this.state.SelectedAppointment.attributes.title}
                              </Typography>
                            </Button>
                            <Button>
                              <Typography style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: 'rgba(192, 192, 192, 0.9)',
                                textTransform: 'none'
                              }}>
                                {AttendenceText[this.state.language]}
                              </Typography>
                            </Button>
                          </Breadcrumbs>
                        </div>
                        <Box style={{ marginTop: "20px" }}>
                          <Box
                            style={webStyle.detailsGrid}
                          >
                            <Grid container spacing={2} className="formContainer"
                              style={{ padding: "30px", marginTop: "20px", height: '255px' }}
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <div >
                                    <div style={webStyle.displayflex}>
                                      <Typography style={webStyle.titleStyle}>
                                        {this.state.SelectedAppointment.attributes.title}
                                      </Typography>

                                    </div>
                                    <div
                                      style={{
                                        borderTop: '1px solid #cbcdd1',
                                        width: '100%',
                                        marginTop: '10px'
                                      }} >
                                    </div>
                                  </div>


                                </Grid>
                                <Grid item xs={3}>
                                  <div >
                                    <Typography style={webStyle.titleStyle}>
                                      {date[this.state.language]} :
                                    </Typography>
                                    <Typography style={webStyle.descStyel}>
                                      {this.callDateChangeFormat(this.state.SelectedAppointment.attributes.start_date)}

                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div > <Typography style={webStyle.titleStyle}>
                                  {Time[this.state.language ]} (UTC) :
                                  </Typography>
                                    <Typography style={webStyle.descStyel}>
                                      {this.state.SelectedAppointment.attributes.start_time} to {this.state.SelectedAppointment.attributes?.end_time}

                                    </Typography></div>
                                </Grid>
                                <Grid item xs={12}>
                                  <div > <Typography style={webStyle.titleStyle}>
                                  {Address[this.state.language ]} :
                                  </Typography>
                                    <Typography style={webStyle.descStyel}>
                                      {this.state.SelectedAppointment.attributes.address}
                                    </Typography></div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <Box style={{ marginTop: '20px' }} >
                          <Grid container >
                            <Grid item xs={12} style={{
                              backgroundColor: 'rgb(238, 236, 232)',
                              borderRadius: '10px',
                            }} >
                              <Grid container style={{
                                paddingBottom: '10px',
                                paddingTop: '10px',
                                marginLeft: '10px',
                              }}>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>{PatientName[this.state.language ]}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>{InviteResponse[this.state.language ]}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>{PhoneNumber[this.state.language ]}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>{PatientResponse[this.state.language ]}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography style={webStyle.titleStyle}>{CoordinatorResponse[this.state.language ]}</Typography>
                                </Grid>
                              </Grid>

                            </Grid>
                            {this.state.attendencePatientList.map((row: any, index: number) => (
                              
                              <Grid item xs={12} key={row.id}>
                                {console.log("@@@row.isPopoverOpen ",row.isPopoverOpen )}

                                <Grid container style={{
                                  paddingBottom: '10px',
                                  paddingTop: '10px',
                                  marginLeft: '10px',
                                }}>
                                  <Grid item xs={2} style={
                                    { display: 'flex', alignItems: "center" }
                                  }>
                                    <Typography style={webStyle.descStyel}>{row.full_name}</Typography>
                                  </Grid>
                                  <Grid item xs={2} style={
                                    { display: 'flex', alignItems: "center" }
                                  }>
                                    <Typography style={webStyle.descStyel}>{row.invite_response || `${NoResponse[this.state.language ]}`}</Typography>                                    
                                  </Grid>
                                  <Grid item xs={2} style={
                                    { display: 'flex', alignItems: "center" }
                                  }>
                                    {this.Phonenumber(row)}
                                  </Grid>
                                  <Grid item xs={2} style={
                                    { display: 'flex', alignItems: "center" }
                                  }>
                                    <Typography style={webStyle.descStyel}>{row.patient_response || `${NoResponse[this.state.language ]}`}</Typography>
                                  </Grid>
                                  <Grid item xs={2} >
                                    <div className="dropdown" style={{
                                      padding: '10px',
                                      alignItems: 'center',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      cursor: 'pointer',
                                      position: "relative"
                                    }}>
                                      <div
                                        className="dropdown-toggle"
                                        data-test-id="dropdownattendence"
                                        onClick={() => this.toggleDropdown(index)}
                                        style={{ alignItems: 'center', display: 'flex', cursor: 'pointer', position: 'relative' }}
                                      >
                                        <Typography
                                          aria-haspopup="true"
                                          style={webStyle.descStyel}
                                        >
                                          {row.coordinator_response || `${NoResponse[this.state.language]}`} <KeyboardArrowDownIcon />
                                        </Typography>
                                      </div>
                                      {/* Conditionally Rendered Dropdown for Each Row */}
                                      {row.isPopoverOpen && (
                                        <div
                                          ref={(el) => (this.dropdownRefs[index] = el)}
                                          className="custom-dropdown"
                                          style={{
                                            position: 'absolute',
                                            background: '#fff',
                                            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                                            padding: '10px',
                                            borderRadius: '6px',
                                            width: '150px',
                                            zIndex: 10,
                                            marginTop: '5px',
                                            left: "-168px"
                                          }}
                                        >
                                          <RadioGroup
                                            value={row.coordinator_response === "notAttended" ? "Not Attended" : "Attended"}
                                            onChange={(event) => this.handleChangeAttendedData(event, index, row.id)}
                                          >
                                            <FormControlLabel
                                              value="Attended"
                                              control={<StyledRadio />}
                                              label="Attended"
                                            />
                                            <FormControlLabel
                                              value="Not Attended"
                                              control={<StyledRadio />}
                                              label="Not Attended"
                                            />
                                          </RadioGroup>
                                        </div>
                                      )}


                                    </div>

                                  </Grid>

                                </Grid>

                              </Grid>
                            ))}
                          </Grid>
                          {this.Attendence()}
                        </Box>
                      </Box>
                    </>
                  ) : (<>
                  <Grid container >
                    <Grid item xs={12} sm={9}  style={{height:"100vh", overflow:"scroll", scrollbarWidth: "none"}}>
                    <Box
                      style={webStyle.mainBox}>
                      <Grid container style={{display:'flex', justifyContent: "space-between"}}>
                        <Grid item xs={12} sm={6}>
                        <Typography style={webStyle.box2}>{visitSchedule[this.state.language ]}</Typography>
                        </Grid>
                        <Grid  item xs={12} sm={6} style={webStyle.boxBothButton}>
                          <Button  style={webStyle.imag1} data-test-id='calender'
                            onClick={this.openCalender} >
                            {MyCalender[this.state.language ]}
                            </Button>
                          <Button style={webStyle.img2}  startIcon={<AddIcon/>}
                            data-test-id='AddSchedule'
                            onClick={this.toggleDrawer} >
                             {addSchedule[this.state.language ]}  </Button>
                        </Grid>
                        </Grid>

                      <Box style={{
                        marginBottom: "10px"
                      }}>
                        <Typography style={webStyle.para}>{titleHeading[this.state.language]}</Typography>
                      </Box>
                      {this.renderMilestoneView()}
                      <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: '20px' }}>
                        <GenericTabs
                          data-test-id="generic_tab"
                          tabs={this.state.tabs}
                          handleSelectTab={this.handleSelectTab}
                          selected={this.state.selectedTab}
                        />

                      </Box>

                      {this.SelectTab()}
                      {this.state.selectedTab === UpcomingTest[this.state.language] && (
                        <>
                          {this.renderUpcommingSchedule()}
                        </>

                      )}

                      {this.renderDrawerMenu()}
                      {this.renderDrawerDetails()}
                      {this.deleteDailog()}
                    </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    </Grid>
                    </Grid>
                  </>)
                }
              </>

            )}

          </MuiPickersUtilsProvider>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledRadio = styled(Radio)({
  "&.Mui-checked": {
    color: "#a046fb !important"
  }
});

const styles = {
  backButton : {
    borderRadius: 5,
    textTransform: 'none' as 'none',
    fontFamily: 'Inter' as "Inter",
    border: "2px solid #A046FB",
    color: "#292929",
    fontWeight: 600,
  },
  sideBackButton: {display:'flex',width:'100%',alignItems:'center',backgroundColor:'rgb(249, 241, 241)',padding:'10px'},
  btnVisit: {color:"#939aad",fontFamily: 'Jost' as 'Jost',fontSize:'14px'},
  textIcon : {color:'#a046fb'},
  btnCalender : {color:"#939aad",fontSize:'14px',fontFamily: 'Jost'},
  sideBarSize: { padding: '30px',maxWidth:'400px' }

}
const EditOptionModalStyle = styled((Box as React.FC<BoxProps>))({

  "& .MuiOutlinedInput-input": {
    padding: 0,
    fontSize: "12px",
    fontFamily: "Jost",
    fontStyle: "normal",
    height: "38px",
    fontWeight: 400,
    color: "#D4D3D3",
    paddingLeft: '16px',
  }, "& .topBox": {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .selectLogo": {
    verticalAlign: 'middle',
    float: 'right',
  },
  "& .searchStudy": {
    border: "1px solid #474747",
    height: "38px",
    fontFamily: "Jost",
    borderRadius: "4px",
    fontStyle: "normal",
    cursor: 'pointer',
    color: "#D4D3D3",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "22px"

  },
  "& .studyName.selected": {
    fontStyle: "normal",
    fontWeight: 600,
    color: "#FFF",
    fontFamily: "Jost",
    fontSize: "14px",
  },
  "& .studyName": {
    paddingTop: '16px',
    fontStyle: "normal",
    fontWeight: 400,
    cursor: 'pointer',
    color: "#D4D3D3",
    fontFamily: "Jost",
    fontSize: "14px",
  },
  "& .container": {
    background: "#2F2F2F",
    boxShadow: "none",
    position: "absolute",
    top: "10.4%",
    right: "18.4%",
    padding: '20px 30px',
    maxWidth: '700px',
    width: '100%',
    borderRadius: "8px",
    "&::after": { 
      content: "''",
      position: "absolute",
      top: "-10px", 
      right: "15px", 
      width: "0",
      height: "0",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #2F2F2F",
    },
    "@media (max-width: 1366px)": {
      maxWidth: '500px',
      left: "auto",
      width: "auto",
    },
    "@media (max-width: 1199px)": {
      maxWidth: '400px',
      left: "auto",
      width: "auto",
    },
    "@media (max-width: 991px)": {
      maxWidth: '300px',
      left: "auto",
      width: "auto",
    },
    "@media (max-width: 691px)": {
      maxWidth: '200px',
      left: "auto",
      width: "auto",
    },
    "@media (max-height: 1366px)": {
      top: "auto",
      left: "auto",
      width: "auto",
    },
    "@media (max-height: 1199px)": {
      top: "auto",
      maxWidth: '174px',
      left: "auto",
      width: "auto",

    },
    "@media (max-height: 991px)": {
      top: "auto",
      left: "auto",
      width: "auto",
    },
    "@media (max-height: 600px)": {
      top: "auto",
    },
    "@media (max-height: 400px)": {
      top: "auto",
    },
    "@media (min-width: 1600px)": {
      left: "auto",
      width: "auto",
    },
    "@media (min-width: 1920px)": {
      top: "auto",
      left: "auto",
      width: "auto",
    }
  },



})
const DrawerStyle = styled(Drawer)<DrawerProps>({
  height: "calc(100vh - 90px)",
  "& .MuiDrawer-paper": {
    borderRadius: '10px 0 0 10px',
  },
  "& .MuiBackdrop-root": {
    backgroundColor: 'transparent'
  }
})
const PasswordDialogStyle = styled(Box)({
  "& .passwordError": {
    maxWidth: "300px",
    fontSize: "12px",
    color: "#F44336",
    fontFamily: "Inter",
    marginTop: "5px",
    marginLeft: "14px"
  },
  "& .dialog-title": {
    color: "#000",
    fontSize: "28px",
    fontWeight: "bolder",
    fontFamily: "Jost",
  },
  "& .MuiDialogContent-root": {
    padding: '8px 40px'
  },

  "& .MuiDialogTitle-root": {
    padding: '0 40px'
  },
  "& .dialog-para": {
    fontFamily: "Jost",
    fontSize: "16px",
    color: "#5E5E5E",
    fontWeight: 400,
    fontStyle: "normal",
    width: '90%'
  },
  "& .change-password-btn": {
    height: '50px',
    width: '45%',
    color: '#000',
    fontFamily: 'Jost',
    fontSize: '14px',
    fontWeight: 'bolder',
    textTransform: 'capitalize',
    border: "2px solid #A046FB",
    marginBottom: '40px',
    borderRadius: "4px",
    marginTop: '32px',
    background: "#FFF",
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)"
  },
  "& .oldPswd": {
    fontFamily: 'Jost',
    height: '49px',
    marginTop: '24px',
  },
  "& .cNewPswd": {
    marginTop: '20px',
    height: '49px',
    fontFamily: 'Jost',
  },
  "& .newPswd": {
    height: '49px',
    marginTop: '20px',
    fontFamily: 'Jost',

  },
})
const webStyle = {
  mainBox: {
    padding: "30px"
  }, 
  mainBoxFirst: {
    padding: "30px"
  },
  addnew: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    marginTop: '10px'
  },
  details: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    marginTop: '10px'
  },
  box21: { backgroundColor: '#ededeb', height: '85px', borderRadius: '10px', padding: "20px", marginTop: "20px"},
  hours: {
    color: '#8B8B8B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '10px'
  },
  duration: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    marginTop: '10px'
  },
  dateNew: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    marginTop: '10px'
  },
  addresstext: {
    color: 'var(--Grey-Base, #939393)',
    fontFamily: 'Inter',
    fontSize: '6px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal', marginLeft: '10px'
  },
  passworderror: {
    maxWidth: "300px",
    fontSize: "12px",
    color: "#F44336",
    fontFamily: "Inter",
    marginTop: "5px",

  },
  titleStyle: {
    color: '#1F3650',
    fontFamily: 'Jost',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '26px'
  },
  descStyel: {
    color: '#454545',
    fontFamily: 'Jost',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    display: "flex",
    alignItems: "center"
  },
  openmilestone: {
    display: 'flex',
    padding: '10px',
    border: '1px solid #ccc',
    cursor: 'pointer',
    alignItems: 'center',
    borderRadius: '5px',
    justifyContent: 'space-between'
  },
  displayflex1: { display: 'flex', justifyContent: 'space-between', marginTop: '20px' },
  displayflex: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  detailsGrid: {
    backgroundColor: '#ededeb',
    borderRadius: '10px',
    cursor:"pointer"
  },

  required: {
    color: '#838383',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
  },
  fullname: {
    color: '#292929',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Jost',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  avatarstyle: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: '#F6EEFF', 
    color: 'black',
  },
  datePicker: {
    '& .MuiInputBase-inputAdornedStart': {
      paddingLeft: 0, 
    },
    '& .MuiInputBase-root': {
      paddingLeft: 0, 
    },
  },
  pagination: {
    '& ul': {
      justifyContent: 'center',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0, 
    },
    '& .Mui-selected': {
      backgroundColor: '#f50057',
      color: '#fff',
    }, '& .MuiButtonBase-root': {
      backgroundColor: '#f50057',
      color: '#fff',
    }
  },
  boxNew: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  boxNewSecound: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  box2: {
    fontSize: 40,
    marginBottom: 30,
    color: "#1F3650",
    fontWeight: 700,
    fontFamily:'jost' 
  },
  box3: {
    display: 'flex',
    justifyContent: 'end',
    height: "8%",
  },
  boxBothButton:{
    display: 'flex',
    height: "8%",
    gap:'10px',
    justifyContent: 'end',
  },
  imag1: {
    fontFamily: "Jost",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "26px",
    textTransform: 'none' as 'none',
    border: '2px solid #a046fb',
    padding: '8px',
    borderRadius: '10px',
    cursor: "pointer"
  },
  img2:
  {
    fontFamily: "Jost",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "26px",
    textTransform: 'none' as 'none',
    border: '2px solid #a046fb',
    padding: '8px',
    borderRadius: '10px',
    cursor: "pointer"
  },
  imgButton2: {
    backgroundColor:'#ffff',
    border:'2px solid #a046fb',
    fontSize:'18px',
    fontFamily:'Jost',
    textTransform:'none' as 'none',
    fontWeight:600,
    borderRadius: '8px'
  },
  imgButtonIcon2: {
    fontSize:'20px'
  },
  para: {
    color: '#414141',
    fontFamily: 'Jost',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
  },
  tab1: {
    color: '#A046FB',
    fontFamily: 'Jost',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
  }, tab2: {
    color: '#A046FB',
    fontFamily: 'Jost',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
  },
  drawerPaper: {
    backgroundColor: 'transparent',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
};
// Customizable Area End
