import React, { CSSProperties } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, Modal, styled, Typography } from "@material-ui/core";
import SidebarController from "./SidebarController";
import {
  dashboardIcon,
  patientIcon,
  taskListIcon,
  visitScheduleIcon,
  sendNotificationIcon,
  communityIcon,
} from "./assets";
import Lottie from "react-lottie";
import { PasswordDialogStyle } from "../../blocks/chat/src/Chat.web";

class Sidebar extends SidebarController {
  render() {
    const dashboard: { [key: string]: string } = {
      en: "Dashboard",
      fr: "Tableau de bord",
      es: "Panel",
      de: "Armaturenbrett",
    };
    const patient: { [key: string]: string } = {
      en: "Patient",
      fr: "Patient",
      es: "Paciente",
      de: "Geduldig",
    };
    const taskList: { [key: string]: string } = {
      en: "Task List",
      fr: "Liste des tâches",
      es: "Lista de tareas",
      de: "Aufgabenliste",
    };
    const visitSchedule: { [key: string]: string } = {
      en: "Visit Schedule",
      fr: "Calendrier des visites",
      es: "Horario de visitas",
      de: "Besuchsplan",
    };
    const sendNotification: { [key: string]: string } = {
      en: "Send Notification",
      fr: "Envoyer une notification",
      es: "Enviar notificación",
      de: "Benachrichtigung senden",
    };
    const community: { [key: string]: string } = {
      en: "Community",
      fr: "Communauté",
      es: "Comunidad",
      de: "Gemeinschaft",
    };
    return (
      <>
      <MainBox>
        <InnerTabBox
          style={
            this.state.selected === "dashboard"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("dashboard")}
        >
          <img
            src={dashboardIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "dashboard"
                ? webStyle.svgImage(
                  "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                )
                  : webStyle.svgImage("brightness(0) invert(1)")
              }
            />
            <Hidden xsDown>
              <Typography
                style={
                  this.state.selected === "dashboard"
                    ? webStyle.text("#5E2A95", 600)
                    : webStyle.text("white")
                }
              >
                {dashboard[this.props.language || 'en']}
              </Typography>
            </Hidden>
          </InnerTabBox>

        <InnerTabBox
          style={
            this.state.selected === "patient"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("patient")}
        >
          <img
            src={patientIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "patient"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
           <Hidden xsDown>
          <Typography
            style={
              this.state.selected === "patient"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            {patient[this.props.language || 'en']}
          </Typography>
          </Hidden>
        </InnerTabBox>

        <InnerTabBox
          style={
            this.state.selected === "task_list"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("task_list")}
        >
          <img
            src={taskListIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "task_list"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Hidden xsDown>
          <Typography
            style={
              this.state.selected === "task_list"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            {taskList[this.props.language || 'en']}
          </Typography>
          </Hidden>
        </InnerTabBox>

        <InnerTabBox
          style={
            this.state.selected === "visit_schedule"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("visit_schedule")}
        >
          <img
            src={visitScheduleIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "visit_schedule"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Hidden xsDown>
          <Typography
            style={
              this.state.selected === "visit_schedule"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            {visitSchedule[this.props.language || 'en']}
          </Typography>
          </Hidden>
        </InnerTabBox>

        <InnerTabBox
          style={
            this.state.selected === "send_notification"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("send_notification")}
        >
          <img
            src={sendNotificationIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "send_notification"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Hidden xsDown>
          <Typography
            style={
              this.state.selected === "send_notification"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            {sendNotification[this.props.language || 'en']}
          </Typography>
          </Hidden>
        </InnerTabBox>

        <InnerTabBox
          style={
            this.state.selected === "community"
              ? (webStyle.box("white") as CSSProperties)
              : (webStyle.box("#5E2A95") as CSSProperties)
          }
          onClick={() => this.selectTab("community")}
        >
          <img
            src={communityIcon}
            alt=""
            width={20}
            height={20}
            style={
              this.state.selected === "community"
                ? webStyle.svgImage(
                    "brightness(1) invert(0.8) sepia(1) saturate(5) hue-rotate(250deg)"
                  )
                : webStyle.svgImage("brightness(0) invert(1)")
            }
          />
          <Hidden xsDown>
          <Typography
            style={
              this.state.selected === "community"
                ? webStyle.text("#5E2A95", 600)
                : webStyle.text("white")
            }
          >
            {community[this.props.language || 'en']}
          </Typography>
          </Hidden>
        </InnerTabBox>
      </MainBox>
      <Modal
        open={this.state.showModal}
      >
        <Box
          style={webStyle.modalContainer as any}
          onClick={this.closeModal}
        >
          <Box style={webStyle.modalBody as any}>
            <Lottie 
              options={{
                loop: true,
                autoplay: true,
                animationData: require("../assets/coming_soon_animation.json"),
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                }
              }}
              height={250}
              width={250}
            />
          </Box>
        </Box>
      </Modal>
      <Dialog
          open={this.state.isCalling}
          maxWidth={"xs"}
          fullWidth
          aria-labelledby="responsive-dialog-title"
        >
          <PasswordDialogStyle>
          <DialogTitle className="dialog-header">
      <Typography className="dialog-title">
        Incoming Call from {this.state.userDetail.name || "Unknown"}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography className="dialog-message">Would you like to accept?</Typography>
    </DialogContent>
    <DialogActions className="dialog-actions">
      <Button
        data-testid="handleRejectCall"
        onClick={this.handleCallReject.bind(this)}
        className="reject-btn"
      >
        Decline
      </Button>
      <Button
        data-testid="handleAcceptCall"
        onClick={this.handleNavigate}
        className="accept-btn"
      >
        Accept
      </Button>
    </DialogActions>
          </PasswordDialogStyle>
        </Dialog>
    </>
    );
  }
}
const MainBox = styled(Box)({
  backgroundColor: "#5E2A95",
    flex:"0 0 15%",
    borderTopRightRadius: 15,
    paddingRight: 20,
    alignItems: "center", 
    "@media (max-width: 850px)": {
     display:"flex",
     height:"fit-content",
     borderRadius:"none",
     flex: "unset",
     flexWrap: "wrap",
     width: "100%"
    }
})

const InnerTabBox = styled(Box)({
  "@media (max-width: 850px)": {
    display: "flex",
    height: 60,
    margin: "30 0 30 0",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "row",
    cursor: 'pointer',
    }
})

const webStyle = {
  text: (color: string, fontWeight: number = 400) => ({
    color,
    fontSize: '16px',
    fontWeight,
    alignSelf: "center",
    cursor: "pointer",
    fontFamily: "Jost",
  }),
  box: (backgroundColor: string) => ({
    display: "flex",
    backgroundColor,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    height: 60,
    margin: "30 0 30 0",
    justifyContent: "start",
    paddingLeft: 50,
    alignItems: "center",
    flexDirection: "row",
    cursor: 'pointer',
    "@media (max-width: 500px)": {
      justifyContent: "end",
      display:"flex",
    },
  }),
  svgImage: (color: string) => ({
    marginRight: 10,
    filter: color,
  }),
  modalContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust opacity as needed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: { 
    height: 300, 
    width: 350, 
    backgroundColor: "white", 
    borderRadius: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
};

export default Sidebar;
