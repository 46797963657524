import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Breadcrumbs,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SideNavbar from "../../../components/src/SideNavbar.web";
import Sidebar from "../../../components/src/Sidebar.web";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { defaultGroup } from "./assets";
import { CommunityList, ChatMessage } from "./GroupsController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const titleHeading: { [key: string]: string } = {
  en: "Welcome to your Community tab! Here, you can view the list of communities and their announcement details.",
  fr: "Bienvenue dans votre onglet Communauté ! Ici, vous pouvez consulter la liste des communautés et les détails de leurs annonces.",
  es: "¡Bienvenido a la pestaña Comunidad! Aquí puede ver la lista de comunidades y los detalles de sus anuncios.",
  de: "Willkommen auf Ihrem Community-Tab! Hier können Sie die Liste der Communities und ihre Ankündigungsdetails einsehen.",
}

const AttachmentsText: { [key: string]: string } = {
  en: "Attachments",
  fr: "Pièces jointes",
  es: "Adjuntos",
  de: "Anhänge",
}

const Back: { [key: string]: string } = {
  en: "Back",
  fr: "Dos",
  es: "Atrás",
  de: "Zurück",
}
// Customizable Area End

import GroupsController, {
  Props,
  configJSON,
} from "./GroupsController";
import CustomDropDownWithSearch from "../../../components/src/CustomDropDownWithSearch";

export default class Groups extends GroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAttachmentContent = () => {
    const { attachmentUrl } = this.state;

    if (attachmentUrl[0].type === "image") {
      return <img src={attachmentUrl[0].url} alt="Attachment" style={{ maxWidth: "100%", height: "auto" }} />;
    } else if (attachmentUrl[0].type  === "pdf") {
      return (
        <iframe
          src={attachmentUrl[0].url}
          title="PDF Attachment"
          width="100%"
          height="500px"
        />
      );
    } else {
      return <video controls width="100%">
      <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>;
    }
  };

  renderAttachmentShow = () => {
    return (
      <Dialog
        open={this.state.isAttachmentsShow}
        onClose={this.handleAttachmentCLose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{AttachmentsText[this.state.language]}</DialogTitle>
        <DialogContent>
          {this.renderAttachmentContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAttachmentCLose} color="primary">
          {Back[this.state.language]}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  renderCommunityWithImage = (val: CommunityList) => {
    return <>
      <Box display={'flex'}>
        <img src={val.attributes.image_url || defaultGroup} style={webStyle.imgLogo} />
        <Box display={'flex'} flexDirection={'column'} justifyContent={"center"}>
          <Typography style={{ fontWeight: 400, fontSize: "1.15rem" }}>{val?.attributes.name}</Typography>
          <Box display={'flex'} alignItems={"center"} pt={"10px"}>
            <Typography style={{ fontWeight: 300, color: "grey", paddingRight: "10px" }}>{val?.attributes.region}</Typography>
            <FiberManualRecordIcon style={{ color: "grey", paddingRight: "10px", fontSize: "12px" }} />
            <Typography style={{ fontWeight: 300, color: "grey" }}>{val?.attributes.member_count}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  }

  formatDateCommunity = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

    const startTime = new Date(date);
    startTime.setHours(10, 0, 0, 0);
    const endTime = new Date(date);
    endTime.setHours(13, 0, 0, 0);

    const formattedTime = (date >= startTime && date < endTime) ? date.toLocaleTimeString('en-US', options) : "01:00 PM";

    return formattedTime;
  };

  renderCommunityPage = () => {
    const NoAnnouncmentsFound: { [key: string]: string } = {
      en: "No Announcments Found",
      fr: "Aucune annonce trouvée",
      es: "No se encontraron anuncios",
      de: "Keine Ankündigungen gefunden",
    }
    const Dashboard: { [key: string]: string } = {
      en: "Dashboard",
      fr: "Tableau de bord",
      es: "Panel",
      de: "Armaturenbrett",
    }
    const Community: { [key: string]: string } = {
      en: "Community",
      fr: "Communauté",
      es: "Comunidad",
      de: "Gemeinschaft",
    }
    const Announcements: { [key: string]: string } = {
      en: "Announcements",
      fr: "Annonces",
      es: "Anuncios",
      de: "Ankündigungen",
    }
    const date: { [key: string]: string } = {
      en: "Date",
      fr: "Date",
      es: "fecha",
      de: "Datum",
    }
    const Time: { [key: string]: string } = {
      en: "Time",
      fr: "temps",
      es: "tiempo",
      de: "Zeit",
    }
    if (!this.state.isCommunityListShow) {
      const CommunityNames: { [key: string]: string } = {
        en: "Community Names",
        fr: "Noms des communautés",
        es: "Nombres de comunidad",
        de: "Community-Namen",
      }
      const NoRecordFound: { [key: string]: string } = {
        en: "No Record Found",
        fr: "Aucun enregistrement trouvé",
        es: "No se encontró ningún registro",
        de: "Kein Datensatz gefunden",
      }

      return <Box>
        <Typography style={webStyle.titleStyle2}>{CommunityNames[this.state.language || 'en']}</Typography>
        {
          this.state.communityForumList?.length > 0 ?
            this.state.communityForumList?.map((item: CommunityList) => {
              return <Box display={'flex'} flexDirection={'column'} mt={"20px"}>
                <Box>
                  <Box display={'flex'} justifyContent={"space-between"} py={'.3rem'} alignItems={"center"}>
                    {this.renderCommunityWithImage(item)}
                    <ArrowForwardIcon style={webStyle.arrowForwardIcon} data-testid="showDetailsPage" onClick={this.handleShowDetailsPage.bind(this, item)} />
                  </Box>
                  <hr style={webStyle.dividerRule} />
                </Box>
              </Box>
            })
            : <Typography>{NoRecordFound[this.state.language || 'en']}</Typography>
        }
      </Box>
    } else {
      return <>
        <Grid container style={{ display: 'flex', justifyContent: "start", alignItems: 'center', gap: '10px' }}>
          <Grid>
            <Button style={webStyle.buttonStyle} startIcon={< ArrowBackIcon style={{ fontSize: "21px", marginLeft: "4px", fontWeight: "bolder" }} />} data-testid="backBtn" onClick={this.handleGoToCommunity}>
              {Back[this.state.language || 'en']}
            </Button>
          </Grid>
          <Grid>
            <Breadcrumbs style={webStyle.breadCrumbStyle} separator={<ArrowRightIcon style={{ color: "#A776FB", fontSize: "25px" }} />} aria-label="breadcrumb">
              <span data-testid="goToDashboard" color="inherit" style={{ color: "grey", cursor: "pointer" }} onClick={this.handleGoToDashboard}>
                {Dashboard[this.state.language || 'en']}
              </span>
              <span data-testid="goToCommunity" onClick={this.handleGoToCommunity} style={{ color: "grey", cursor: "pointer" }} color="inherit">
                {Community[this.state.language || 'en']}
              </span>
              <span color="inherit" style={{ color: "grey", cursor: "default" }}>
                {this.state.communityForumDetails.attributes.name}
              </span>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Box ml={"27px"} mt={"30px"}>
          {this.renderCommunityWithImage(this.state.communityForumDetails)}
          <hr style={webStyle.dividerRule} />
          <Box>
            <Typography style={{ color: "#A776FB", fontSize: "1.2rem", fontFamily: "Jost", fontWeight: 700, textAlign: "left" }}>{Announcements[this.state.language || 'en']}</Typography>
            <hr style={webStyle.lineRuleDetailStyle} />
          </Box>
          {this.state.announcementChats?.data?.attributes?.messages?.length > 0 ? this.state.announcementChats?.data?.attributes?.messages?.map((val: ChatMessage) => {
            return <Box>
              <Box display={'flex'} alignItems={"center"} mt={'1.5rem'}>
                <Box width={"15rem"}>
                  <Typography style={{ fontWeight: 600 }}>{date[this.state.language || 'en']}:</Typography>
                  <p style={webStyle.annoucementColor}>{this.formatDateCommunity(val?.attributes?.updated_at)}</p>
                </Box>
                <Box width={"15rem"}>
                  <Typography style={{ fontWeight: 600 }}>{Time[this.state.language || 'en']}:</Typography>
                  <p style={webStyle.annoucementColor}>{this.formatTime(val?.attributes?.created_at)} to {this.formatTime(val?.attributes?.updated_at)}</p>
                </Box>
              </Box>
              <p style={webStyle.annoucementColor} onClick={() => this.handleAttachmentsShow(val.attributes.attachments)}>{val?.attributes?.message}</p>
              <hr style={webStyle.dividerRule} />
            </Box>
          }) : <Box>
            <Typography style={{ paddingTop: '2rem', textAlign: "center", ...webStyle.annoucementColor }}>{NoAnnouncmentsFound[this.state.language || 'en']}</Typography>
            <hr style={webStyle.dividerRule} />
          </Box>
          }

        </Box>
      </>
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <SideNavbar
          navigation={this.props.navigation}
          id={this.props.id}
          showStudy={true}
          showButtonChats
          showButtonNotification
          language={this.state.language}
          handleLanguageSwitch={this.handleLanguageSwitch}
        />
        <Box style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
          <Sidebar navigation={this.props.navigation} id={this.props.id} selected="community" language={this.state.language} />
          <Box padding={'30px'} width={"100%"}>
            <Typography style={webStyle.titleStyle}>Community</Typography>
            <p style={webStyle.paraGraph}>{titleHeading[this.state.language]}</p>
            {this.renderCommunityPage()}
          </Box>
        </Box>
        {this.renderAttachmentShow()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle: {
    border: "2px solid #a046fb",
    borderRadius: "9px",
    padding: "5px",
    textTransform: "none" as "none",
    fontWeight: 700
  },
  titleStyle: {
    fontWeight: 700 as 700,
    fontSize: '40px',
    fontFamily: "Jost",
    lineHeight: "50px",
    textAlign: "left" as 'left',
    color: '#1F3650'
  },
  paraGraph: {
    fontWeight: 400 as 400,
    fontSize: '14px',
    lineHeight: '26px',
    fontFamily: "Jost",
    textAlign: "left" as "left"
  },
  titleStyle2: {
    fontWeight: 500 as 500,
    fontSize: "32px",
    fontFamily: "Jost",
    lineHeight: "50px",
    textAlign: "left" as "left"
  },
  imgLogo: {
    width: '55px',
    height: "55px",
    border: ".1px solid white",
    borderRadius: "100%",
    marginRight: "20px"
  },
  arrowForwardIcon: {
    color: "#A776FB",
    paddingRight: '30px',
    cursor: "pointer",
    paddingTop: "30px"
  },
  dividerRule: {
    width: "100%",
    color: "#80808033",
    marginTop: "25px"
  },
  lineRuleDetailStyle: {
    color: 'rgb(167, 118, 251)',
    width: '92.5px',
    padding: '0px',
    margin: '0px',
    height: '2.2px',
    backgroundColor: 'rgb(167, 118, 251)',
    marginTop: '5px',
    border: '.1px solid rgb(167, 118, 251)',
    borderRadius: '50px'
  },
  breadCrumbStyle: {
    width: '100%',
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#F1F1F1DE',
    paddingLeft: '15px',
    borderRadius: '8px',
    fontFamily: "Jost",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "left" as "left"
  },
  annoucementColor: {
    color: 'gray',
    fontFamily: 'Jost',
    fontSize: "18px",
    cursor: "pointer",
  }
};
// Customizable Area End
