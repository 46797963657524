import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {getStorageData, setStorageData} from "../../../framework/src/Utilities"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  seconds: number;
  open: boolean;
  otpScreen: boolean;
  emailScreen: boolean;
  emailErrorText: string;
  passwordSuccess: boolean;
  emailError: any;
  otpError: any
  otp: any;
  validOtp: boolean;
  confirmPassword: any;
  passwordError: any;
  confirmPasswordError: any
  token: string,
  otpConfirm: string;
  isLoading: boolean;
  openSnackbar: boolean;
  showPrivacy:boolean,
  timerActive:boolean,
  showTerms:boolean,
  showPassword:boolean,
  showConfirmPassword:boolean,
  roleId:number,
  fullName:string,
  validateOtpMsg: string;
  otpTimerReach: boolean;
  openSnackbarInvalidToken: boolean;
  maximumTimerOtp: number;
  selectedLanguage: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  emailRegistrationAPIid: any;
  emailOTPConfirmationAPIid: any;
  passwordRegistrationAPIid: any;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  countDownTimerReachSignUp: any;
  btnTextSignUp: string;
  currentCountryCode: any;
  countdownSignUp: any;
  signUpEmailOTPConfirmationAPIid: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      seconds: 60,
      open: false,
      otpScreen: false,
      emailScreen: true,
      emailErrorText: "",
      passwordSuccess: false,
      emailError: null,
      otpError: null,
      otp: null,
      validOtp: false,
      confirmPassword: "",
      passwordError: "",
      confirmPasswordError: "",
      token: "",
      otpConfirm: "",
      isLoading: false,
      openSnackbar: false,
      showPrivacy:false,
      timerActive:true,
      showTerms:false,
      showPassword:false,
      showConfirmPassword:false,
      roleId:0,
      fullName:'',
      validateOtpMsg: '',
      otpTimerReach: false,
      openSnackbarInvalidToken: false,
      maximumTimerOtp: 0,
      selectedLanguage:'en'
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      await this.handleRestApiResponse(message);
    }
  
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      await this.handleNavigationPayload(message);
    }
  
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.handleCountryCode(message);
    }
  }
  
  private async handleRestApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
  
    if (apiRequestCallId && responseJson) {
      await this.processApiResponse(apiRequestCallId, responseJson, errorReponse);
    }
  }
  
  private async processApiResponse(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    switch (apiRequestCallId) {
      case this.validationApiCallId:
        this.handleValidationApiResponse(responseJson);
        break;
      case this.createAccountApiCallId:
        this.handleCreateAccountApiResponse(responseJson, errorReponse);
        break;
      case this.emailRegistrationAPIid:
        this.handleEmail(responseJson);
        break;
      case this.emailOTPConfirmationAPIid:
        this.handleSignUpEmailOTP(responseJson);
        break;
      case this.passwordRegistrationAPIid:
        this.handlePasswordRegistration(responseJson, errorReponse);
        break;
    }
  }
  
  private handleValidationApiResponse(responseJson: any) {
    if (this.arrayholder && this.arrayholder.length !== 0) {
      const regexData = this.arrayholder[0];
  
      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }
  
      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules,
        });
      }
  
      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }
  
  private handleCreateAccountApiResponse(responseJson: any, errorReponse: any) {
    if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );
  
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
      this.send(msg);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  
    this.parseApiCatchErrorResponse(errorReponse);
  }
  
  private handlePasswordRegistration(responseJson: any, errorReponse: any) {
    if (responseJson && !errorReponse) {
      this.handleSetPassword(responseJson);
    }
  }
  
  private async handleNavigationPayload(message: Message) {
    const otpAuthTkn = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );
  
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  }
  
  private handleCountryCode(message: Message) {
    const selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );
  
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0 ? selectedCode.split("+")[1] : selectedCode,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleLanguageChangeRegistration = (event: React.ChangeEvent<{ value: unknown }>) => {
    localStorage.setItem("language", event.target.value as string )
    this.setState({ selectedLanguage:event.target.value as string});
  };

  otpTimerSignup = () => {
    clearInterval(this.countdownSignUp);
    this.countdownSignUp = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState((prevState) => ({
          seconds: prevState.seconds - 1,
        }));
      } else {
        if (this.state.timerActive) {
          this.handleResendOtp();
          clearInterval(this.countdownSignUp);
          this.setState({ timerActive: false });
        }
      }
    }, 1000);
  };

  handleEmailOTP = (responseJson: any) => {
    
    this.setState({ open: true, isLoading: false, roleId:responseJson.data.attributes.role_id, fullName:responseJson.data.attributes.full_name
  });

  }

  handleSignUpEmailOTP = (responseJson: any) => {
    if(responseJson?.errors?.[0]?.otp ==='Invalid token'){ 
      this.setState({isLoading: false, openSnackbarInvalidToken: true,}) 
    }
      this.setState({ open: true, isLoading: false, fullName:responseJson?.data?.attributes?.full_name, roleId: responseJson?.data?.attributes?.role_id}); 
     if(responseJson?.meta?.message ==='Maximum OTP attempts reached. Please try again after 5 minutes.'){ 
      this.maximumOtpReachTimer(); 
      this.setState({isLoading:false,validOtp:true, otpTimerReach: true, maximumTimerOtp:300}) 
    }else if(responseJson?.meta?.message?.includes('Please enter')){ 
      this.setState({isLoading:false,validOtp:true, validateOtpMsg:responseJson?.meta?.message}) 
    } 
  } 

  handleEmail = (responseJson: any) => {
    setStorageData("token",responseJson.data?.token)
    if (responseJson && responseJson.errors) {
      if (responseJson.errors[0].account === "Account already activated") {
        this.setState({ emailError: true, emailErrorText: "Account already activated", isLoading: false })
        this.props.navigation.navigate("EmailAccountLoginBlock")
      }
      if (responseJson.errors[0].email === "email not found") {
        this.setState({ emailError: true, emailErrorText: "Email is not verified, please enter once again", isLoading: false })
      }
    } else if (responseJson.data?.is_activated && responseJson.data.message === "Account already activated") {
      this.setState({ emailError: true, emailErrorText: "Account already activated",isLoading: false});
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else if (responseJson.error?.message === "Please enter valid email address") {
      this.setState({otpScreen:false,emailError: true,emailErrorText:responseJson.error?.message, isLoading: false });
      return;
    } else {
      this.setState({ otpConfirm: responseJson.data?.attributes?.pin, token: responseJson.data?.token, emailScreen: false, otpScreen: true, isLoading: false }, () => {
        this.otpTimerSignup()
      })
    }
  }

  handleSetPassword = (responseJson: any) => {
    this.setState({ open: false, otpScreen: false, passwordSuccess: true, isLoading: false });
  }
 
  async componentDidMount() {
    let selected_language = (await localStorage.getItem("language")) || "en";
    console.log("@@@selected_language",selected_language)
    this.setState({ selectedLanguage: selected_language });
  }

  async componentWillUnmount() {
    clearInterval(this.countdownSignUp);
  }

  displayTime() {
    const minutes = Math.floor(this.state.seconds / 60);
    const remainingSeconds = this.state.seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  displayOtpReachTime() {
    const minutes = Math.floor(this.state.maximumTimerOtp / 60);
    const remainingSeconds = this.state.maximumTimerOtp % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  maximumOtpReachTimer = () => {
    clearInterval(this.countDownTimerReachSignUp);
    this.countDownTimerReachSignUp = setInterval(() => {
      if (this.state.maximumTimerOtp > 0) {
        this.setState((prevState) => ({
          maximumTimerOtp: prevState.maximumTimerOtp - 1,
        }));
        if(this.state.otpTimerReach){
          this.setState({validOtp: true, validateOtpMsg: `Maximum OTP attempts reached. Please try again after 5 minutes - ${this.displayOtpReachTime()}`})
        }else{
          this.setState({otp: '',validateOtpMsg: '',validOtp: false,})
        }
      } else {
        clearInterval(this.countDownTimerReachSignUp);
        this.setState({otpTimerReach: false,maximumTimerOtp:0,})
      }
      if(this.state.maximumTimerOtp === 0){
        this.setState({validateOtpMsg: '',validOtp: false})
      }
    }, 1000);
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  postEmailRegistration = () => {
    setStorageData("email",this.state.email)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    let formDataCampaign = {
      "data": {
        "type": "email_account",
        "attributes":
        {
          "email": this.state.email,
          "role_name": "Site Coordinator",
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailRegistrationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaign)
    );
    this.emailRegistrationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postEmailOTPConfirmation =  async() => {
    this.setState({isLoading: true})
    const tokenData = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    let formDataCampaignOtp = {
      "token":tokenData,
      "pin": Number(this.state.otp)
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailOTPConfirmationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaignOtp)
    );
    this.emailOTPConfirmationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postPasswordRegistration = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    let formDataCampaignPassword = {
      "data": {
        "type": "email_account",
        "attributes": {
          "full_name": this.state.fullName,
          "email": this.state.email,
          "password": this.state.password,
          "role_id": this.state.roleId,
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRegistrationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaignPassword)
    );
    this.passwordRegistrationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleClickOpen = () => { 
    if (this.state.otp === null || this.state.otp === '' || this.state.otp?.length !== 6) {
      this.setState({ validOtp: true,validateOtpMsg:'Please enter a valid 6-digit OTP' })
    } 
    else {
      this.setState({ 
        isLoading: false,
      },()=>{
        this.postEmailOTPConfirmation()
      }) 
    } 
  }; 

  handleClose = () => {
    this.setState({ open: false, password:'', confirmPassword:'', passwordError:'', confirmPasswordError:'', showPassword:false, showConfirmPassword:false });
  };
  handleEmailSubmit = () => {
    setStorageData("selected_language", this.state.selectedLanguage)
    if (this.state.emailError === false) {
      this.postEmailRegistration()
      this.setState({ isLoading: true })

    } else {
      this.setState({ emailError: true, emailErrorText: 'Email is not verified, please enter once again' })
    }
  }

  submitPassWord = () => {
    if (this.state.password === "" && this.state.confirmPassword === "") {
      this.setState({ passwordError: "please enter password", confirmPasswordError: "please enter confirm password" })
    } else if (this.state.password === "") {
      this.setState({ passwordError: "please enter password" })
    } else if (this.state.confirmPassword === "") {
      this.setState({ confirmPasswordError: "please enter confirm password" })
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ confirmPasswordError: "Password do not match" })
    }
    else if (this.state.passwordError === "" && this.state.confirmPasswordError === "") {
      this.postPasswordRegistration();
      this.setState({
        isLoading: true
      })

    }
  }

  handleEmailChange = (value: string) => {
    const regex = configJSON.emailRegex
    if (value.trim() === '') {
      this.setState({ emailError: false, emailErrorText: '', email: value });
    } else if (value.match(regex)) {
      this.setState({ emailError: false, emailErrorText: '', email: value });
    } else {
      this.setState({ emailError: true, emailErrorText: 'Please enter a valid email address', email: value });
    }
  }

  registeredUserUpdatedOtpVal = (updatedOtp:string) =>{
    return updatedOtp?.length === 0?true:false
  }

  handleOtpChange = (e: any) => { 
    if (e.key === 'Backspace') {  
      const updatedOtp  =  this.state.otp.slice(0, -1); 
      this.setState({ otp: updatedOtp, validOtp: this.registeredUserUpdatedOtpVal(updatedOtp),validateOtpMsg:'Please enter a valid 6-digit OTP' });
    } else { 
      const sanitizedValues  =  e.target.value.replace(/\D/g, '').slice(0, 6);
      if (sanitizedValues) { 
           this.setState({ otp: sanitizedValues, validOtp: sanitizedValues.length === 0?true:false,validateOtpMsg:'Please enter a valid 6-digit OTP'  });
      } else {       
          this.setState({ validOtp: false, });    
      }  
    }     
  }; 

  handlePasswordChange = (value: any) => {
    const strongPasswordRegex = configJSON.passwordRegex;
    let passwordError = '';
    if (value !== '') {
      passwordError = strongPasswordRegex.test(value)
        ? ''
        : 'Password must contain 1 Uppercase, 1 Lowercase, 1 Special and 1 Numeric Characters!';
    }
    this.setState((prevState) => ({
      password: value,
      passwordError,
      confirmPasswordError: value === prevState.confirmPassword ? '' : prevState.confirmPasswordError,
    }));
  };

  handleConfirmPasswordChange = (value: any) => {
    let confirmPasswordError = '';
    if (value !== '' && value !== this.state.password) {
      confirmPasswordError = 'Password do not match';
    }
    this.setState({
      confirmPassword: value,
      confirmPasswordError: confirmPasswordError,
    });
  };

  loginNavigationButton = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleResendOtp = () => {
    if (!this.state.timerActive) {
      this.postEmailRegistration();
      this.setState({ openSnackbar: true, seconds: 60, timerActive: true });
      this.otpTimerSignup();
    }
  };

  handleSnackbarClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackbar: false });
  };

  handleEditEmailAddress = () => {
    clearInterval(this.countdownSignUp);
    clearInterval(this.countDownTimerReachSignUp);
    this.setState({
      otpScreen: false, emailScreen: true, otp: null,validOtp: false,validateOtpMsg:'',otpTimerReach: false, seconds: 60, timerActive: true,
    })
  }
  handleTermsClick = () => {
    this.setState({showTerms:true})
   };
   handlePrivacyClick = () => {
    this.setState({showPrivacy:true})
   }
   handleCloseTerms = () => {
    this.setState({showTerms:false})
   }
   handleClosePrivacy = () => {
    this.setState({showPrivacy:false})
   }
   handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword
    }))
   }
   handleClickShowConfirmPassword = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword
    }))
   }
  // Customizable Area End
}
