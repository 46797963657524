import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export interface CommunityList {
  "id": string,
  "type": string,
  "attributes": {
    "id": string,
    "name": string,
    "description": string,
    "studies": string[],
    "region": string,
    "account_id": string,
    "member_count": string,
    "created_at": string,
    "updated_at": string,
    "image_url": string,
    "joined": boolean,
    "announcement_channel": {
      "id": string,
      "created_at": string,
      "updated_at": string,
      "name": string,
      "chat_type": string,
      "community_id": string,
      "is_announcement": boolean,
      "data": {
        "attributes": {
          "chat": {
            "id": string
          }
        }
      }
    }
  }
}

export interface ChatMessage {
  "id": string;
  "type": string;
  "attributes": {
    "id": string;
    "message": string;
    "account_id": string;
    "chat_id": string;
    "created_at": string;
    "updated_at": string;
    "is_mark_read": boolean;
    attachments:  [
      {
          "id": number;
          "url": string;
          "type": string;
      }
  ];
  }
};

export interface AnnouncementChats {
  "data": {
    "id": string;
    "type": string;
    "attributes": {
      "id": string;
      "name": string;
      "accounts_chats": [
        {
          "id": string;
          "type": string;
          "attributes": {
            "account_id": string;
            "status": string;
            "muted": boolean;
          }
        }
      ],
      "messages": ChatMessage[]
    };
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isCommunityListShow: boolean;
  communityForumList: CommunityList[];
  language: string;
  communityForumDetails: CommunityList;
  announcementChats: AnnouncementChats;
  isAttachmentsShow: boolean;
  attachmentUrl: [{ id: number; url: string; type: string; }];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCommunityListApiCallId: string = "";
  getAnnouncementChatsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isCommunityListShow: false,
      communityForumList: [],
      language: localStorage.getItem("language") || 'en',
      communityForumDetails: {
        "id": '',
        "type": '',
        "attributes": {
          "id": '',
          "name": '',
          "description": '',
          "studies": [],
          "region": '',
          "account_id": '',
          "member_count": '',
          "created_at": '',
          "updated_at": '',
          "image_url": '',
          "joined": false,
          "announcement_channel": {
            "id": '',
            "created_at": '',
            "updated_at": '',
            "name": '',
            "chat_type": '',
            "community_id": '',
            "is_announcement": false,
            "data": {
              "attributes": {
                "chat": {
                  "id": ""
                }
              }
            }
          }
        }
      },
      announcementChats: {
        "data": {
          "id": "",
          "type": "chat",
          "attributes": {
            "id": '',
            "name": "Demo19 announcement",
            "accounts_chats": [
              {
                "id": "",
                "type": "accounts_chats",
                "attributes": {
                  "account_id": '',
                  "status": "",
                  "muted": false
                }
              }
            ],
            "messages": []
          },
        }
      },
      isAttachmentsShow: false,
      attachmentUrl: [{
        id: 0,
        url: "",
        type: ""
      }],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      runEngine.debugLog("API Message Recived", message);
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getCommunityListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.handleGetCommunityListData(apiResponse)
      }
      if (this.getAnnouncementChatsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.handleGetAnnouncementChatsData(apiResponse)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleGetCommunityListData = (apiResponse: { data: CommunityList[] }) => {
    this.setState({ communityForumList: apiResponse?.data })
  }

  handleLanguageSwitch = (lang: string) => {
    localStorage.setItem('language', lang)
    this.setState({ language: lang })
  }
  handleGetAnnouncementChatsData = (apiResponse: AnnouncementChats) => {
    this.setState({ announcementChats: apiResponse })
  }


  async componentDidMount() {
    this.getCommunityForumList();
  }

  handleShowDetailsPage = (item: CommunityList) => {
    this.getAnnouncementChats(item.attributes.announcement_channel.data.attributes.chat.id)
    this.setState({ isCommunityListShow: true, communityForumDetails: item })
  }

  handleGoToDashboard = () => {
    this.props.navigation.navigate("Dashboard")
  }

  handleGoToCommunity = () => {
    this.setState({ isCommunityListShow: false }, () => {
      this.getCommunityForumList()
    })
  }


  // ALL API CALL
  getCommunityForumList = () => {
    const token = localStorage.getItem("token");
    const studyId = localStorage.getItem("studyId");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCommunityListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCommunityListEndPoint + `?study_id=${JSON.parse(JSON.stringify(studyId))}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAnnouncementChats = (chatId: string) => {
    const token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAnnouncementChatsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAnnouncementChatsEndPoint + `?chat_id=${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAttachmentsShow = (attachments: [{ id: number; url: string; type: string; }]) => {
    if (attachments === null) return;
    this.setState({
      isAttachmentsShow: true,
      attachmentUrl: attachments,
    })
  }

  handleAttachmentCLose = () => {
    this.setState({
      isAttachmentsShow: false
    })
  }
  // Customizable Area End
}
